import React, { useState, useEffect, useContext } from 'react'

import { Dropdown, Modal, ProgressBar } from 'react-bootstrap';
import { ICN_ADD, ICN_LANGUAGE, ICN_SUCCESS, ICN_TOTAL_LECTURE, ICN_TOTAL_TIME, IMG_CARD, PYMENT_SUCCESSFULL, REMOVE_ICN, WIZARD_CHECK_ICN, WIZARD_DOT_ICN } from '../Icon';
import CartContext from '../../../Store/CartContext';
import RestService from '../../../Services/api.service';
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Check } from "@mui/icons-material";
import Emtycard from "../../../Assets/Images/Empty state-PhotoRoom.png"
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ToastMessage from '../../../Store/ToastHook';
import { useNavigate } from 'react-router-dom';


const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        // <Check className="QontoStepIcon-completedIcon" />
        <div>{WIZARD_CHECK_ICN}</div>
      ) : (
        <div >{WIZARD_DOT_ICN}</div>
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const steps = [
  "Cart",
  "Payment",
  "Order Confirmation",
];




function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const BsModal = ({ children, setShow, show, size = "md", headerAction = null }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();

  // const handleStepChange = (step) => {
  //   setActiveStep(step);
  // };

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  const proceedToCheckOut = () => {
    setActiveStep(1);
  }

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };
  //   const [show, setShow] = useState(false);
  const [cartResponse1, setCartResponse1] = useState(null);
  const [cartId, setCardId] = useState();
  const [checkout, setCheckout] = useState(false);
  const [courseSid, setCourseSid] = useState('');
  const [agree, setAgree] = useState(false);
  // const [enterCouponCode, setEnterCouponCode] = useState('');
  // const [couponCode, setCouponCode] = useState(null);
  // const [discount, setDiscount] = useState(0);
  // const [originalCartPrice, setOriginalCartPrice] = useState(null);

  // const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);

  const { currency, cartResponse, setCartResponse,
    couponCode, setCouponCode,
    discount, setDiscount,
    originalCartPrice, setOriginalCartPrice,
    enterCouponCode,
    setEnterCouponCode,
    totalPrice,
    setTotalPrice
  } = useContext(CartContext);


  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : '';

  //display cart
  const displayCart = () => {
    try {
      // spinner.show();
      RestService.displayCart().then(
        response => {
          if (response.status === 200) {
            // const updatedTotalPrice = {
            //     ...response.data,
            //     "totalPrice": 0
            // }

            // setCartResponse1(updatedTotalPrice);
            setCartResponse1(response.data);
            setCardId(response.data.cartId);
            setCartResponse(response.data);
            setCouponCode(response.data.couponCode);
            setDiscount(response.data.discount);
            setOriginalCartPrice(response.data.originalCartPrice);
            setTotalPrice(response.data.totalPrice);
            setEnterCouponCode(response.data?.couponCode === null ? '' : response.data?.couponCode);


          }
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on displayCart()", err)
    }
  }

  //apply coupon code
  const applyCouponCode = (action) => {
    try {
      // spinner.show();
      const consumerSid = user?.sid;
      const newCouponCode = enterCouponCode.length !== 0 ? enterCouponCode : couponCode
      RestService.applyCouponCode(action, cartId, consumerSid, newCouponCode).then(
        response => {
          if (response.status === 200) {
            if (action === 'REMOVE') {
              setEnterCouponCode('');
            }
            setCartResponse1(response.data);
            setCardId(response.data.cartId);
            setCartResponse(response.data);
            setCouponCode(response.data.couponCode);
            setDiscount(response.data.discount);
            setOriginalCartPrice(response.data.originalCartPrice);
            setTotalPrice(response.data.totalPrice);

          }
        },
        err => {
          ToastMessage({ type: "warning", message: `${err.response.data.message}`, time: 2000 });
        }
      )
    } catch (err) {
      console.error("error occur on applyCouponCode()", err)
    }
  }


  //delete item from cart
  const deleteFromCart = (courseSid) => {
    try {
      // spinner.show();
      // const courseSid = cardcontent.;

      RestService.deleteFromCart(cartId, courseSid).then(
        response => {
          if (response.status === 200) {
            setCouponCode(response.data.couponCode);
            setDiscount(response.data.discount);
            setOriginalCartPrice(response.data.originalCartPrice);
            setCartResponse1(response.data);
            setCartResponse(response.data);
            setTotalPrice(response.data.totalPrice);

          }
        },
        err => {
          ToastMessage({ type: "warning", message: `${err.response.data.message}`, time: 2000 });
        }
      )
    } catch (err) {
      console.error("error occur on deleteFromCart()", err)
    }
  }

  useEffect(() => {
    if ('user' in localStorage) {
      displayCart();
    }

  }, [cartResponse?.contentCount, couponCode, totalPrice]);

  useEffect(() => {
    if (cartResponse1 !== null && cartResponse1?.cartDetails?.length === 0) {
      setTimeout(() => {
        setActiveStep(0);
      }, 2000);

    }


  }, [cartResponse1])

  //complete razor pay order
  const createRazorPayOrder = (orderId, paymentId, signature) => {
    try {
      // spinner.show();
      // const subCategorySid = subCategorySid;
      const payload = {
        "cartId": cartId,
        "consumerSid": user?.sid,
        "razorpayOrderId": orderId,
        "razorpayPaymentId": paymentId,
        "razorpaySignature": signature,
        "status": "succeeded"
      }
      RestService.createRazorPayOrder(payload).then(
        response => {
          if (response.status === 200) {
            // setActiveStep(2);
            setActiveStep(3);
            // setShowPaymentSuccess(true);
            // setTimeout(() => {
            //   reloadPage();
            // }, 4000)
            // setCartResponse(response.data);
            // window.location.reload(); 
          }
        },
        err => {
          console.error(err);
          ToastMessage({ type: "warning", message: `${err.response.data.message}`, time: 2000 });
        }
      )
    } catch (err) {
      console.error(err);
    }
  }

  //other than success pay order in razorpay
  //like closing razor-pay pop up modal
  const createRazorPayOrderCancelled = () => {
    try {
      // spinner.show();
      // const subCategorySid = subCategorySid;
      const payload = {
        "cartId": null,
        "consumerSid": null,
        "razorpayOrderId": null,
        "razorpayPaymentId": null,
        "razorpaySignature": null,
        "status": "cancelled"
      }
      RestService.createRazorPayOrder(payload).then(
        response => {
          ToastMessage({ type: "warning", message: 'Payment Cancelled' });
        },
        err => {
          ToastMessage({ type: "warning", message: `${err.response.data.message}`, time: 2000 });
        }
      )
    } catch (err) {
      console.error("error occur on createRazorPayOrderCancelled()", err)
    }
  }


  async function showRazorpay() {
    if (cartResponse1 !== null && cartResponse1.totalPrice === 0) {
      createRazorPayOrder(null, null, null);
    }
    else {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const data = await fetch(`https://lxp.learnlytica.com/insled/v4/razorpay/order?currency=${currency}&cart_id=${cartId}`, {
        method: "POST",
      }).then((t) => t.json());

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        currency: currency,
        amount: data.totalPrice,
        order_id: data.razorPayOrderId,
        name: user.appuser.name,
        description: "Thank you for nothing. Please give us some money",
        handler: function (response) {
          // const abb = { razorpay_payment_id: 'pay_NKogdPLB4QLIsM', razorpay_order_id: 'order_NKog9dKXaKKQXI', razorpay_signature: 'fba8a2b9abe88ca48188bfe194297891264fb8b1268f0d326ffe8dfbd99a1755' }
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
          createRazorPayOrder(response.razorpay_order_id, response.razorpay_payment_id, response.razorpay_signature);

        },
        modal: {
          confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
          // This function is executed when checkout modal is closed
          // There can be 3 reasons when this modal is closed.
          ondismiss: async (reason) => {
            const {
              reason: paymentReason, field, step, code,
            } = reason && reason.error ? reason.error : {};
            // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly. 
            if (reason === undefined) {
              createRazorPayOrderCancelled();
              // handlePayment('Cancelled');
            }
            // Reason 2 - When modal is auto closed because of time out
            else if (reason === 'timeout') {
              // handlePayment('timedout');
            }
            // Reason 3 - When payment gets failed.
            else {
              // handlePayment('failed', {
              //   paymentReason, field, step, code,
              // });
            }
          },
        },

        // prefill: {
        //   name: "Rajat",
        //   email: "rajat@rajat.com",
        //   phone_number: "9899999999",
        // },
        prefill: {
          name: user?.appuser?.name ? user.appuser.name : "Rajat",
          email: user?.appuser?.emailId ? user.appuser.emailId : "rajat@rajat.com",
          phone_number: user?.appuser?.phoneNumber ? user.appuser.phoneNumber : "9899999999",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  }

  const reloadPage = () => {
    setShow(false);
    displayCart();
    navigate('/mycourses');
    // window.location.href = window.location.href; // This will cause a reload
    // window.location.reload(true);
  }


  return (
    <>
      {/* <Button variant="primary" onClick={() => setShow(true)}>
        Custom Width Modal
      </Button> */}
      <ToastContainer />
      <Modal
        show={show}
        onHide={() => {
          if (activeStep < 2) {
            setShow(false);
            setActiveStep(0);
          }
          else if (activeStep === 3) {
            reloadPage();
          }
        }}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Cart
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

            <>
              <Stack sx={{ width: '100%' }} spacing={4}>
                <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={QontoStepIcon}
                      // onClick={() => handleStepChange(index)}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  {activeStep === 0 &&
                    'user' in localStorage && cartResponse1 !== null && cartResponse1?.cartDetails !== null && (

                      <div className='row'>
                        <div className='col-xl-8 col-lg-8 col-md-8 col-sm-12'>
                          {
                            cartResponse1 !== null && cartResponse1.cartDetails?.map((item, index) => {
                              return (
                                <div class="row mt-5 mb-5">



                                  <div class="col-12 col-sm-12 ">
                                    <div class="card" >
                                      <div className='row g-0'>
                                        {/* {
                                    item.thumbnail !== null &&
                                    <div className='col-md-4'>
                                      <img src={item.thumbnail} height="100%" width="100%" />
                                    </div>
                                  } */}

                                        <div className='col-md-12'>
                                          <div class="card-body ">

                                            <div className='d-flex' style={{ justifyContent: "space-between" }}>
                                              <h4 class="card-title mt-4">{item.courseName}</h4>
                                              <div class=" mt-4" style={{ float: "inline-end", fontFamily: "Inter", fontSize: "20px", fontWeight: "600", color: "#101828" }}>{currency === 'INR' ? '₹' : '$'}
                                                {
                                                  currency === 'INR' ?
                                                    Math.floor(item.price)
                                                    :
                                                    Math.floor(item.internationalPrice)

                                                }

                                              </div>


                                            </div>
                                            <div className='d-flex flex-wrap pt-3 pb-3 gap-2'>
                                              {/* <button className='card-top-btn flex-grow-1 mb-2 mr-1'>{item.categoryName}</button>
                                              <button className='card-top-btn flex-grow-1 mb-2 mr-1'>{item.subCategoryName}</button> */}
                                              {
                                                item.tags.map((tag) => {
                                                  return (
                                                    <button className='card-top-btn flex-grow-1 mb-2 mr-1'>{tag}</button>
                                                  )
                                                })
                                              }

                                              {/* <button className='card-top-btn'>Git</button> */}
                                            </div>
                                            <div className='d-flex gap-5 mb-4 mt-4'>
                                              <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_LECTURE}</span><span className='mt-5'>{item.totalContent} Lectures</span></div>
                                              <div className='total card-subtitle'> <span className='mx-2'>{ICN_TOTAL_TIME}</span> <span className='mt-5'>{item.totalHours} Total Hours</span></div>
                                            </div>
                                            <div className='d-flex' style={{ justifyContent: "space-between", textAlign: "center", alignItems: "center" }}>
                                              <p class="card-text gap-2"><span >{ICN_LANGUAGE}</span>{item.languages.toString()} </p>
                                              {/* <Link to={`/course/${item.title}`} class="card-view-course">View Course {ICN_NEXT}</Link> */}
                                              <button
                                                className='removebtn'
                                                onClick={() => { setCourseSid(item.courseSid); deleteFromCart(item.courseSid) }}
                                              >
                                                {REMOVE_ICN}
                                                Remove
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>



                                </div>
                              )
                            })
                          }
                        </div>

                        {cartResponse1?.cartDetails.length !== 0 &&
                          < div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 mt-5 mb-5'>
                            <div className=' card p-5' style={{ boxShadow: 'none' }}>
                              <div className='checkout '>
                                <div>Total :</div>
                                <div className='checkoutamt'>
                                  {currency === 'INR' ? '₹' : '$'}{cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice)}

                                </div>
                                {
                                  couponCode !== null &&
                                  <div className='d-flex px-4'>
                                    <div className='course-card-h-offer'>{originalCartPrice}</div>
                                    <div className='course-card-h-discount mx-3'>{`${discount}%`}</div>
                                  </div>
                                }
                                <button className='checkoutbtn mt-2 mb-2' onClick={proceedToCheckOut}>Checkout</button>
                              </div>
                              <hr />
                              <div className='promotion'>
                                <p>PROMO CODES</p>
                                {
                                  currency === 'INR' &&
                                    cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 5000 ?
                                    <p>Add courses worth ₹{5000 - Math.floor(cartResponse1?.totalPrice)} more </p>
                                    :
                                    cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 500 &&
                                    <p>Add courses worth ${500 - Math.floor(cartResponse1?.totalPrice)} more </p>
                                }
                                {
                                  couponCode !== null &&
                                  <span className='d-flex'> <div className='apply-coupn mx-2' onClick={() => applyCouponCode('REMOVE')}>x</div> {couponCode} <div className="mx-2" style={{ color: "red" }}>is applied</div></span>
                                }
                                {/* <span> x KEEPLEARNING is applied</span> */}
                                {/* <div className='d-flex' >
     <input className='applycoupn' placeholder='jhjhjh' />
  <button className='applycoupn-btn'>Apply</button>
     </div> */}

                              </div>

                              <div class="d-flex ">
                                <div class="input-group mt-4 ">
                                  <input
                                    type="text"
                                    className='form-control applycoupn'
                                    value={enterCouponCode}
                                    disabled={(currency === 'INR' ? cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 5000
                                      : cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 500)
                                      || couponCode !== null}
                                    onChange={(e) => setEnterCouponCode(e.target.value)}
                                    placeholder='Enter Promo Code here'
                                  />
                                  <button class=" applycoupn-btn" type="button" id="button-addon1" data-mdb-ripple-color="dark"
                                    style={{
                                      opacity: (enterCouponCode.length === 0 || (currency === 'INR' ? cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 5000
                                        : cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 500) || couponCode !== null) ? '0.5' : '1'
                                    }}
                                    disabled={enterCouponCode.length === 0 || (currency === 'INR' ? cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 5000
                                      : cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 500) || couponCode !== null} onClick={() => applyCouponCode('APPLY')}>
                                    Apply
                                  </button>
                                </div>
                              </div>

                            </div>
                          </div>}


                      </div>
                    )
                  }
                  {activeStep === 0 && (cartResponse1 === null || cartResponse1?.cartDetails === null || cartResponse1?.cartDetails.length === 0) &&

                    <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>

                      <img src={Emtycard} alt="Empty Card" style={{ marginBottom: '-70px' }} />
                      <Link to="/course-library" onClick={() => setShow(false)} className='btn-primary '><span className='px-2'>{ICN_ADD} </span>Add Course to Cart</Link>
                    </div>




                  }
                  {activeStep === 1 && (
                    <>
                      <div className='row'>



                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                          {
                            cartResponse1 !== null && cartResponse1.cartDetails?.map((item, index) => {
                              return (
                                <div class="row mt-5 mb-5">



                                  <div class="col-12 col-sm-12 ">
                                    <div class="card" >
                                      <div className='row g-0'>
                                        {/* {
                                    item.thumbnail !== null &&
                                    <div className='col-md-4'>
                                      <img src={item.thumbnail} height="100%" width="100%" />
                                    </div>
                                  } */}

                                        <div className='col-md-12'>
                                          <div class="card-body ">

                                            <div className='d-flex' style={{ justifyContent: "space-between" }}>
                                              <h4 class="card-title mt-4">{item.courseName}</h4>
                                              <div class=" mt-4" style={{ float: "inline-end", fontFamily: "Inter", fontSize: "20px", fontWeight: "600", color: "#101828" }}>{currency === 'INR' ? '₹' : '$'}
                                                {
                                                  currency === 'INR' ?
                                                    Math.floor(item.price)
                                                    :
                                                    Math.floor(item.internationalPrice)

                                                }
                                              </div>
                                            </div>
                                            <div className='d-flex flex-wrap pt-3 pb-3 gap-2'>
                                              {/* <button className='card-top-btn flex-grow-1 mb-2 mr-1'>{item.categoryName}</button>
                                              <button className='card-top-btn flex-grow-1 mb-2 mr-1'>{item.subCategoryName}</button> */}
                                              {
                                                item.tags.map((tag) => {
                                                  return (
                                                    <button className='card-top-btn flex-grow-1 mb-2 mr-1'>{tag}</button>
                                                  )
                                                })
                                              }

                                              {/* <button className='card-top-btn'>Git</button> */}
                                            </div>
                                            <div className='d-flex gap-5 mb-4 mt-4'>
                                              <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_LECTURE}</span><span className='mt-5'>{item.totalContent} Lectures</span></div>
                                              <div className='total card-subtitle'> <span className='mx-2'>{ICN_TOTAL_TIME}</span> <span className='mt-5'>{item.totalHours} Total Hours</span></div>
                                            </div>
                                            <div className='d-flex' style={{ justifyContent: "space-between", textAlign: "center", alignItems: "center" }}>
                                              <p class="card-text gap-2"><span >{ICN_LANGUAGE}</span>{item.languages.toString()} </p>
                                              {/* <Link to={`/course/${item.title}`} class="card-view-course">View Course {ICN_NEXT}</Link> */}
                                              <button
                                                className='removebtn'
                                                onClick={() => { setCourseSid(item.courseSid); deleteFromCart(item.courseSid) }}
                                              >
                                                {REMOVE_ICN}
                                                Remove
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>



                                </div>



                              )
                            })
                          }

                        </div>



                        {/* cartResponse1 !== null && cartResponse1.cartDetails !== null ? */}


                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-5 mb-5 ' style={{ borderLeft: "1px solid #EAECF0" }}>


                          <div className=' card p-5' style={{ boxShadow: 'none', border: "none" }}>

                            <div className='oredersummary mb-5'>Order Summary</div>
                            <div className='row mt-3' style={{ justifyContent: "space-between" }}>
                              <div className='d-flex'>
                                <div className='ordersummaryname'>Name</div>
                                {/* <div className='ordersummaryvalue' style={{ marginLeft: 'auto' }}>Mayank Singh</div> */}
                                {
                                  user?.appuser?.name &&
                                  <div className='ordersummaryvalue' style={{ marginLeft: 'auto' }}>{user?.appuser?.name}</div>
                                }


                              </div>

                            </div>
                            <div className='row mt-3' style={{ justifyContent: "space-between" }}>
                              <div className='d-flex'>
                                <div className='ordersummaryname'>Email</div>
                                {
                                  user?.appuser?.emailId &&
                                  <div className='ordersummaryvalue' style={{ marginLeft: 'auto' }}>{user?.appuser?.emailId}</div>
                                }
                                {/* <div className='ordersummaryvalue' style={{ marginLeft: 'auto' }}>mstict16@gmail.com</div> */}


                              </div>

                            </div>


                            <div className=' d-flex p-3 mt-5 mb-2' style={{ justifyContent: "space-between", alignItems: "center" }}>
                              <div className='totalamt'>Sub Total </div>
                              <div className='checkoutamt' style={{ marginLeft: "auto" }}>
                                {currency === 'INR' ? '₹' : '$'}{cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice)}
                              </div>
                            </div>
                            {

                              couponCode !== null &&
                              <div className=' d-flex p-3 mb-5' style={{ justifyContent: "space-between", alignItems: "center" }}>

                                <div className='totalamt'>Discount </div>
                                <div className='checkoutamt' style={{ marginLeft: "auto" }}>
                                  {`${discount}%`}
                                </div>

                              </div>
                            }

                            <div className='promotion mt-5'>
                              <p>PROMO CODES</p>
                              {
                                currency === 'INR' &&
                                  cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 5000 ?
                                  <p>Add courses worth ₹{5000 - Math.floor(cartResponse1?.totalPrice)} more </p>
                                  :
                                  cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 500 &&
                                  <p>Add courses worth ${500 - Math.floor(cartResponse1?.totalPrice)} more </p>
                              }
                              {
                                couponCode !== null &&
                                <span className='apply-coupn'> <div onClick={() => applyCouponCode('REMOVE')}>x</div> {couponCode} is applied</span>
                              }
                              {/* <span> x KEEPLEARNING is applied</span> */}
                              {/* <div className='d-flex' >
<input className='applycoupn' placeholder='jhjhjh' />
<button className='applycoupn-btn'>Apply</button>
</div> */}

                            </div>




                            <div class="d-flex ">
                              <div class="input-group mt-4 ">
                                <input
                                  type="text"
                                  className='form-control applycoupn'
                                  value={enterCouponCode}
                                  disabled={(currency === 'INR' ? cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 5000
                                    : cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 500)
                                    || couponCode !== null}
                                  onChange={(e) => setEnterCouponCode(e.target.value)}
                                  placeholder='Enter Promo Code here'
                                />
                                <button class=" applycoupn-btn" type="button" id="button-addon1" data-mdb-ripple-color="dark"
                                  style={{
                                    opacity: (enterCouponCode.length === 0 || (currency === 'INR' ? cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 5000
                                      : cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 500) || couponCode !== null) ? '0.5' : '1'
                                  }}
                                  disabled={enterCouponCode.length === 0 || (currency === 'INR' ? cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 5000
                                    : cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice) < 500) || couponCode !== null} onClick={() => applyCouponCode('APPLY')}>
                                  Apply
                                </button>
                              </div>
                            </div>



                            <div className=' d-flex p-3 mt-5 mb-5' style={{ justifyContent: "space-between", alignItems: "center", background: "var(--Primary-50, #F9F5FF)" }}>
                              <div className='totalamt'>Total </div>
                              <div className='checkoutamt' style={{ marginLeft: "auto" }}>
                                {currency === 'INR' ? '₹' : '$'}{cartResponse1 !== null && Math.floor(cartResponse1?.totalPrice)}
                              </div>
                              {
                                couponCode !== null &&
                                <div className='d-flex px-4'>
                                  <div className='course-card-h-offer'>{originalCartPrice}</div>
                                  <div className='course-card-h-discount mx-3'>{`${discount}%`}</div>
                                </div>
                              }


                            </div>

                            <div class="form-group mb-5">
                              <label class="form-check-label"><input type="checkbox" required="required" onClick={(e) => setAgree(e.target.checked)} />  I agree with the <Link to="/legal/terms" target='blank'> terms and conditions</Link></label>
                            </div>



                            <button className='checkoutbtn mt-2 mb-2' style={{ opacity: !agree && '0.5' }} disabled={!agree} onClick={showRazorpay}>Continue to payment</button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {activeStep === 3 && (
                    <div className='payment-failed container text-center p-5'>
                      {PYMENT_SUCCESSFULL}
                      <button onClick={reloadPage} className='btn-primary '>Access your course now</button>
                    </div>
                  )}
                </div>
                {/* <div>
                  <button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                    Back
                  </button>
                  <button variant="contained" onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </button>
                </div> */}
              </Stack>

            </>
          }



        </Modal.Body>
      </Modal >
    </>
  );
}









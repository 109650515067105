let HOSTNAME = window.location.origin; // Storing  a  Host  Name in global variable
// if (HOSTNAME !== null && ((HOSTNAME.indexOf('localhost') !== -1) || (HOSTNAME.indexOf('127.0.0.1') !== -1)))
// let HOSTNAME = "http://Gnosis-UI-LB-489410036.ap-south-1.elb.amazonaws.com";
// Local development sever will be used from now onwards
HOSTNAME = "https://lxp.learnlytica.com"

export const API_PATH = HOSTNAME;

let API_HOST = HOSTNAME + "/insled/v1/"
let API_HOST_V2 = HOSTNAME + "/insled/v2/"
let API_HOST_V3 = HOSTNAME + "/insled/v3/"
let API_HOST_V4 = HOSTNAME + "/insled/v4/"

const GLOBELCONSTANT = {
    ALL_COURSES:{
        GET_CONSUMER_COURSES: API_HOST_V2 + "consumer/course/get",
        GET_ALL_CATEGORIES_AND_SUB_CATEGORIES: API_HOST_V2 + "consumer/course/get/category/sub-category",
        GET_CONSUMER_COURSE_DETAILS: API_HOST_V2 + "consumer/course/details",
        GET_TOP5_COURSES: API_HOST_V2 + "consumer/course/top-5",
        GET_ALL_CONSUMER_COURSE_SUB_CATEGORIES: API_HOST_V2 + "consumer/course/sub-categories/get",
        NEWSLETTER_SUBSCRIBE: API_HOST_V2 + "consumer/newsletter/subscribe",
        PROCEED_TO_TRAINING: API_HOST_V2 + "consumer/course/training/proceed",
        CONTACTUS_FORM: API_HOST_V2 + "contact-us",
        GET_UPLOAD_PROFILE_PICTURE: API_HOST_V2 + "consumer/profile/get",
        UPLOAD_PROFILE_PICTURE: API_HOST_V2 + "consumer/profile/picture/upload",
        CURATE_COURSE: API_HOST_V4 + "{courseSid}/curate",
        NOTIFY_ME: API_HOST_V2 + "consumer/notify-me",
        SEARCH_COURSE: API_HOST_V2 + "consumer/course/search",
        VIEW_ALL_SEARCHED_COURSES: API_HOST_V2 + "consumer/course/view-all"
    },
    AUTH:{  
        CONSUMER_SIGNUP:API_HOST_V2 + "consumer/signup",
        LOGIN: API_HOST + "login",
        AUTO_LOGIN: API_HOST_V4 + "consumer/auto-login",
        Google_AUTO_LOGIN: API_HOST + "consumer/google/login",
        GET_LOGOUT_TIMES: API_HOST + "logout-times",
        FORGOT_PASSWORD: API_HOST_V2 + "forgot-password",
        CHANGE_FORGOT_PASSWORD: API_HOST_V2 + "forgot-password/change",
        UPDATE_CONSUMER_PASSWORD: API_HOST_V2 + "updateConsumerPassword",
        GET_USER_ENROLLED_COURSES: API_HOST_V2 + "consumer/course/enrolled",
        EDIT_CONSUMER_PROFILE: API_HOST_V2 + "consumer/profile/edit"
    },
    CART_CONTROLLER: {
        ADD_TO_CART: API_HOST_V4 + "cart/add",
        DELETE_FROM_CART: API_HOST_V4 + "cart/delete",
        DISPLAY_CART: API_HOST_V4 + "cart/display",
        CREATE_RAZORPAY_ORDER: API_HOST_V4 + "razorpay/complete",
        INITIATE_ORDER_REFUND: API_HOST_V4 + "razorpay/refund",
        PRINT_ORDER_DETAILS: API_HOST_V2 + "consumer/order/{cartId}/print",
        PRINT_ORDER_DETAILS_PDF: API_HOST_V2 + "consumer/order/{cartId}/print/pdf",
        APPLY_COUPON_CODE: API_HOST_V4 + "cart/{cartId}/coupon"
    },
    ORDERS:{
        GET_USER_ORDER_DETAILS: API_HOST_V2 + "consumer/order/get"
    },
    WORK_WITH_US: {
        JOIN_OUR_TEAM: API_HOST_V2 + "consumer/join-team",
        BECOME_A_TRAINER: API_HOST_V2 + "consumer/become-trainer",
        PARTNER_WITH_US: API_HOST_V2 + "consumer/partner"
    },
    BLOG: {
        GET_BLOG_DETAILS: API_HOST_V4 + "blog/{blogSlug}/details/get",
        COMMENT_ON_BLOG: API_HOST_V4 + "blog/{blogSlug}/comment",
        GET_COMMENT_REPLIES: API_HOST_V4 + "blog/{blogSlug}/comment/{commentId}/reply/get",
        POST_COMMENT:API_HOST_V4 + "blog/{blogSlug}/comment",
        POST_COMMENT_REPLIES: API_HOST_V4 + "blog/{blogSlug}/comment/{commentId}/reply",
        LIKE_BLOG: API_HOST_V4 + "blog/{blogSlug}/like",
        LIKE_CONVERSATION: API_HOST_V4 + "blog/{blogSlug}/comment/{commentId}/like"
    }
}


export default GLOBELCONSTANT;
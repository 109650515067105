import React, { useState, useEffect, useContext } from 'react';
import { GO_BACK, ICN_COURSE_MENUE, ICN_HEADER_EXPAND, ICN_LANGUAGE, ICN_MORE_FILTER, ICN_NEXT, ICN_SEARCH, ICN_TOTAL_LECTURE, ICN_TOTAL_TIME, IMG_CARD, NEXT_PAGINATION_ICON, PREV_PAGINATION_ICON } from '../../../Components/Common/Icon';
import RestService from '../../../Services/api.service';
import { Link } from 'react-router-dom';
import Select from "react-select";
import SkeletonLoader from '../../../Components/Common/SkeletonLoader';
import DropdownItemforSidebar from '../../../Components/Common/Utills/DropdownItemforSidebar';
import { useNavigate } from 'react-router-dom';
import { Stack, Pagination, Button } from '@mui/material';
import CartContext from '../../../Store/CartContext';
import AnimationWrapper from '../../../Components/Common/Animation Ref/AnimationWrapper';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';
import { Tooltip } from "react-tooltip";
import { Spinner } from 'react-bootstrap';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cleanup function to cancel the timeout if value or delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]); // Only re-call effect if value or delay changes

  return debouncedValue;
}


const Library = () => {
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterLanguage, setFilterLanguage] = useState('');
  const [allCourses, setAllCourses] = useState([]);
  const [categoriesSubCategories, setCategoriesSubCategories] = useState([]);
  const [visibleCategories, setVisibleCategories] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loaderSideBar, setLoaderSideBar] = useState(true);
  const [filterPriceRating, setFilterPriceRating] = useState('');
  const [selectedSortBy, setSelectedSortBy] = useState(undefined);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [selectedSubCategory, setSelectedSubCategory] = useState(undefined);
  const [selectedOtherFilter, setSelectedOtherFilter] = useState(undefined);
  const [selectedSubCategorySidebar, setSelectedSubCategorySidebar] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');

  const [categorySid, setCategorySid] = useState('');
  const [subCategorySid, setSubCategorySid] = useState('');

  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(null);

  const debouncedQuery = useDebounce(searchTerm, 500); // Debounce query by 500ms

  const [sortBy, setSortBy] = useState('Select');
  const [filterCategories, setFilterCategories] = useState('Categories');
  const [filterSubCategories, setFilterSubCategories] = useState('subCategories');
  const [pageSize, setPageSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { currency } = useContext(CartContext);

  const otherFilter = [
    { value: 'Free', label: 'Free' },
    { value: 'Paid', label: 'Paid' },
    { value: 'Instructor-Led', label: 'Instructor-Led' },
    { value: 'Self Paced', label: 'Self Paced' }
  ]

  const sortby = [
    { value: 'Newest', label: 'Newest' },
    { value: 'Price- Low to high', label: 'Price-Low to high' },
    { value: 'Price- High to low', label: 'Price- High to low' },
    { value: 'Rating- Low to high', label: 'Rating- Low to high' },
    { value: 'Rating- High to low', label: 'Rating- High to low' }

  ]

  const paginationStyle = {
    fontSize: "15px", // Adjust the size as needed
  };


  const stackContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "25px 0px",
    padding: "15px 0px",
    borderTop: "1px solid #EAECF0",
    fontSize: "25px"


  };

  // Function to check if any of the select inputs has a non-default value
  const shouldShowReset = () => sortBy !== 'Select' || filterCategories !== 'Categories' || filterSubCategories !== 'subCategories';


  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : '';

  const navigate = useNavigate();

  const Goback = () => {
    navigate("/all-courses")
  }
  const filterCourses = () => {
    if (searchTerm.trim() === '') {
      setFilteredCourses(allCourses); // Show all courses if no search term
    } else {
      const filtered = allCourses.filter(course =>
        course.courseName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCourses(filtered);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (searchTerm.length > 1) {
      setLoading(true)
    }
    else {
      setLoading(false)
    }

    // filterCourses(); // Filter courses on every input change
  };

  const handlePageChange = (event, value) => {
    // Update the state with the selected page number
    setCurrentPage(value);
    // You can perform any other actions with the page number here
  };
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const toggleCategories = (categorySid) => {
    if (visibleCategories.includes(categorySid)) {
      setVisibleCategories(visibleCategories.filter((sid) => sid !== categorySid));
    } else {
      setVisibleCategories([...visibleCategories, categorySid]);
    }
  }

  const resetRadioInputs = () => {
    const radioInputs = document.querySelectorAll('input[type="radio"]');
    radioInputs.forEach((input) => {
      input.checked = false;
    });
  };

  const handleDetailsToggle = () => {
    // Set filterPriceRating to an empty string when details tab is closed
    if (!document.getElementById('details').open) {
      setFilterPriceRating('');
      resetRadioInputs();
    }
  };

  const handleFilterChange = (value) => {
    setFilterPriceRating(value);
    resetRadioInputs();
  };

  // const callFilterApi = () => {
  //   if ('user' in localStorage) {
  //     getCosumerCoursesForUserFilterPriceRating();
  //   }
  //   else {
  //     getCosumerCoursesForFilterPriceRating();
  //   }
  // }

  const handleCourseSid = (courseSid) => {
    localStorage.setItem('courseSid', courseSid)
  }

  const handleOtherFilterChange = (selectedOption) => {
    // Update selected subcategory
    setSelectedOtherFilter(selectedOption);
  };

  const handleSortByChange = (selectedOption) => {
    // Update selected subcategory
    setSelectedSortBy(selectedOption);
  };

  const handleCategoryChange = (selectedOption) => {
    // Update selected subcategory
    setSelectedCategory(selectedOption);
    // Check if the selected option is null
    if (selectedOption === null) {
      // Set categorySid to ''
      setCategorySid('');
      setCategoryName('');
      setSubCategorySid('');
      setSelectedSubCategory(null);

    } else {
      // Update categorySid based on the selected option's value
      setCategorySid(selectedOption.value);
      setCategoryName(selectedOption.label);
      /* to reset filter by sub categories dropdown, since selecting different 
      catergories when already subcategories selected, i need to reset
      both the dropdown and name in selected subcategories and show the new subcategories dropdown  */
      setSubCategorySid('');
      setSelectedSubCategory(null);
    }
  };

  const handleSubCategoryChange = (selectedOption) => {
    // Update selected subcategory
    setSelectedSubCategory(selectedOption);
    // Check if the selected option is null
    if (selectedOption === null) {
      // Set subCategorySid to ''
      setSubCategorySid('');
      setSelectedSubCategory(null);
    } else {
      // Update subCategorySid based on the selected option's value
      setSubCategorySid(selectedOption.value);
    }
  };



  const searchCourse = () => {
    setLoading(true)
    try {

      RestService.searchCourse(searchTerm).then(
        response => {
          ReactGA.event({
            category: "Global Search",
            action: "Changed",
            label: "Course Search", // optional
            value: searchTerm, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
          setSearchResults(response.data)
          setLoading(false)
        },
        err => {
          setLoading(false);
          console.error(err);
        }
      )
    } catch (err) {
      setLoading(false)
      console.error("error occur on searchCourse()", err)
    }
  }

  //get all courses for view more
  const viewAllSearchedCourses = () => {
    try {

      const pageNo = currentPage;
      const pageSize = 6;
      let payload = {};
      if ('user' in localStorage) {
        payload = {
          "consumerSid": user?.sid
        };
      }
      if (selectedOtherFilter !== undefined) {
        if (selectedOtherFilter !== null) {
          if (selectedOtherFilter.label === 'Free') {
            payload.paid = -1;
            payload.courseType = 0;
          }
          else if (selectedOtherFilter.label === 'Paid') {
            payload.paid = 1;
            payload.courseType = 0;
          }
          else if (selectedOtherFilter.label === 'Instructor-Led') {
            payload.paid = 0;
            payload.courseType = 1;
          }
          else if (selectedOtherFilter.label === 'Self Paced') {
            payload.paid = 0;
            payload.courseType = -1;
          }
        }
        else {
          payload.paid = 0;
          payload.courseType = 0;
          payload.priceFilter = 0;
          payload.ratingFilter = 0;
        }
      }

      if (selectedSortBy !== undefined) {
        if (selectedSortBy !== null) {
          if (selectedSortBy.label === 'Price- High to low') {
            payload.priceFilter = 1;
            payload.ratingFilter = 0;
          }
          else if (selectedSortBy.label === 'Price-Low to high') {
            payload.priceFilter = -1;
            payload.ratingFilter = 0;
          }
          else if (selectedSortBy.label === 'Rating- Low to high') {
            payload.priceFilter = 0;
            payload.ratingFilter = -1;
          }
          else if (selectedSortBy.label === 'Rating- High to low') {
            payload.priceFilter = 0;
            payload.ratingFilter = 1;
          }
        }
        else {
          payload.paid = 0;
          payload.courseType = 0;
          payload.priceFilter = 0;
          payload.ratingFilter = 0;
        }
      }

      if (categorySid.length !== 0) {
        payload.categorySid = categorySid;
      }
      if (subCategorySid.length !== 0) {
        payload.subCategorySid = subCategorySid
      }
      RestService.viewAllSearchedCourses(currency, pageNo, pageSize, searchTerm, payload).then(
        response => {
          if (response.status === 200) {
            ReactGA.event({
              category: "Filter",
              action: "Clicked",
              label: "View more Searching", // optional
              value: searchTerm, // optional, must be a number
              nonInteraction: true, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            });
            setAllCourses(response.data);
            // setFilteredCourses(response.data);
            setPageSize(response.data.length !== 0 ? response.data[0].totalPages : 0);
            setCategoryDescription(response.data.length !== 0 ? response.data[0].categoryDescription : '');
            setTimeout(() => {
              setLoader(false);
            }, 2000);

          }

        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on viewAllSearchedCourses()", err)
    }
  }

  //get all courses
  const getCosumerCourses = () => {
    try {
      setLoader(true);
      const pageNo = currentPage;
      const pageSize = 6;
      let payload = {};
      if (selectedOtherFilter !== undefined) {
        if (selectedOtherFilter !== null) {
          if (selectedOtherFilter.label === 'Free') {
            payload.paid = -1;
            payload.courseType = 0;
          }
          else if (selectedOtherFilter.label === 'Paid') {
            payload.paid = 1;
            payload.courseType = 0;
          }
          else if (selectedOtherFilter.label === 'Instructor-Led') {
            payload.paid = 0;
            payload.courseType = 1;
          }
          else if (selectedOtherFilter.label === 'Self Paced') {
            payload.paid = 0;
            payload.courseType = -1;
          }
        }
        else {
          payload.paid = 0;
          payload.courseType = 0;
          payload.priceFilter = 0;
          payload.ratingFilter = 0;
        }
      }

      if (selectedSortBy !== undefined) {
        if (selectedSortBy !== null) {
          if (selectedSortBy.label === 'Price- High to low') {
            payload.priceFilter = 1;
            payload.ratingFilter = 0;
          }
          else if (selectedSortBy.label === 'Price-Low to high') {
            payload.priceFilter = -1;
            payload.ratingFilter = 0;
          }
          else if (selectedSortBy.label === 'Rating- Low to high') {
            payload.priceFilter = 0;
            payload.ratingFilter = -1;
          }
          else if (selectedSortBy.label === 'Rating- High to low') {
            payload.priceFilter = 0;
            payload.ratingFilter = 1;
          }
        }
        else {
          payload.paid = 0;
          payload.courseType = 0;
          payload.priceFilter = 0;
          payload.ratingFilter = 0;
        }
      }

      if (categorySid.length !== 0) {
        payload.categorySid = categorySid;
      }
      if (subCategorySid.length !== 0) {
        payload.subCategorySid = subCategorySid
      }
      RestService.getCosumerCourses(pageNo, pageSize, currency, payload).then(
        response => {
          if (response.status === 200) {
            ReactGA.event({
              category: "Filter",
              action: "Clicked",
              label: "consumer click not login", // optional
              value: categorySid, // optional, must be a number
              nonInteraction: true, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            });
            setAllCourses(response.data);
            // setFilteredCourses(response.data);
            setPageSize(response.data.length !== 0 ? response.data[0].totalPages : 0);
            setCategoryDescription(response.data.length !== 0 ? response.data[0].categoryDescription : '');
            localStorage.removeItem('sid');
            localStorage.removeItem('name');
            setTimeout(() => {
              setLoader(false);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 2000);

          }

        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getCosumerCourses()", err)
    }
  }

  //get all courses for footer
  const getCosumerCoursesForFooter = (newCategorySid, newCategoryName) => {
    try {
      ReactGA.event({
        category: "Footer",
        action: "Clicked",
        label: newCategoryName, // optional
        value: newCategorySid, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });

      const pageNo = currentPage;
      const pageSize = 6;
      let payload = {};
      if (selectedOtherFilter !== undefined) {
        if (selectedOtherFilter !== null) {
          if (selectedOtherFilter.label === 'Free') {
            payload.paid = -1;
            payload.courseType = 0;
          }
          else if (selectedOtherFilter.label === 'Paid') {
            payload.paid = 1;
            payload.courseType = 0;
          }
          else if (selectedOtherFilter.label === 'Instructor-Led') {
            payload.paid = 0;
            payload.courseType = 1;
          }
          else if (selectedOtherFilter.label === 'Self Paced') {
            payload.paid = 0;
            payload.courseType = -1;
          }
        }
        else {
          payload.paid = 0;
          payload.courseType = 0;
          payload.priceFilter = 0;
          payload.ratingFilter = 0;
        }
      }

      if (selectedSortBy !== undefined) {
        if (selectedSortBy !== null) {
          if (selectedSortBy.label === 'Price- High to low') {
            payload.priceFilter = 1;
            payload.ratingFilter = 0;
          }
          else if (selectedSortBy.label === 'Price-Low to high') {
            payload.priceFilter = -1;
            payload.ratingFilter = 0;
          }
          else if (selectedSortBy.label === 'Rating- Low to high') {
            payload.priceFilter = 0;
            payload.ratingFilter = -1;
          }
          else if (selectedSortBy.label === 'Rating- High to low') {
            payload.priceFilter = 0;
            payload.ratingFilter = 1;
          }
        }
        else {
          payload.paid = 0;
          payload.courseType = 0;
          payload.priceFilter = 0;
          payload.ratingFilter = 0;
        }
      }

      if (categorySid.length !== 0) {
        payload.categorySid = categorySid;
      }
      if (newCategorySid !== null || newCategorySid !== undefined) {
        payload.categorySid = newCategorySid;
      }
      if (subCategorySid.length !== 0) {
        payload.subCategorySid = subCategorySid
      }
      RestService.getCosumerCourses(pageNo, pageSize, currency, payload).then(
        response => {
          if (response.status === 200) {
            setAllCourses(response.data);
            // setFilteredCourses(response.data);
            setPageSize(response.data.length !== 0 ? response.data[0].totalPages : 0);
            setCategoryDescription(response.data.length !== 0 ? response.data[0].categoryDescription : '');
            localStorage.removeItem('sid');
            localStorage.removeItem('name');
            setCategoryName(newCategoryName);
            setCategorySid(newCategorySid);
            setTimeout(() => {
              setLoader(false);
            }, 2000);

          }

        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getCosumerCourses()", err)
    }
  }

  //get all courses for user
  const getCosumerCoursesForUser = () => {
    try {
      // spinner.show();
      // const subCategorySid = subCategorySid;
      setLoader(true);
      const pageNo = currentPage;
      const pageSize = 6;
      const consumerSid = user?.sid;
      let payload = {
        "consumerSid": consumerSid,
      };
      if (selectedOtherFilter !== undefined) {
        if (selectedOtherFilter !== null) {
          if (selectedOtherFilter.label === 'Free') {
            payload.paid = -1;
            payload.courseType = 0;
          }
          else if (selectedOtherFilter.label === 'Paid') {
            payload.paid = 1;
            payload.courseType = 0;
          }
          else if (selectedOtherFilter.label === 'Instructor-Led') {
            payload.paid = 0;
            payload.courseType = 1;
          }
          else if (selectedOtherFilter.label === 'Self Paced') {
            payload.paid = 0;
            payload.courseType = -1;
          }
        }
        else {
          payload.paid = 0;
          payload.courseType = 0;
          payload.priceFilter = 0;
          payload.ratingFilter = 0;
        }
      }

      if (selectedSortBy !== undefined) {
        if (selectedSortBy !== null) {
          if (selectedSortBy.label === 'Price- High to low') {
            payload.priceFilter = 1;
            payload.ratingFilter = 0;
          }
          else if (selectedSortBy.label === 'Price-Low to high') {
            payload.priceFilter = -1;
            payload.ratingFilter = 0;
          }
          else if (selectedSortBy.label === 'Rating- Low to high') {
            payload.priceFilter = 0;
            payload.ratingFilter = -1;
          }
          else if (selectedSortBy.label === 'Rating- High to low') {
            payload.priceFilter = 0;
            payload.ratingFilter = 1;
          }
        }
        else {
          payload.paid = 0;
          payload.courseType = 0;
          payload.priceFilter = 0;
          payload.ratingFilter = 0;
        }
      }

      if (categorySid.length !== 0) {
        payload.categorySid = categorySid;
      }
      if (subCategorySid.length !== 0) {
        payload.subCategorySid = subCategorySid
      }
      RestService.getCosumerCoursesForUser(pageNo, pageSize, currency, payload).then(
        response => {
          if (response.status === 200) {
            ReactGA.event({
              category: "Filter",
              action: "Clicked",
              label: consumerSid, // optional
              value: categorySid, // optional, must be a number
              nonInteraction: true, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            });
            setAllCourses(response.data);
            // setFilteredCourses(response.data);
            setPageSize(response.data.length !== 0 ? response.data[0].totalPages : 0);
            setCategoryDescription(response.data.length !== 0 ? response.data[0].categoryDescription : '');
            localStorage.removeItem('sid');
            localStorage.removeItem('name');
            setTimeout(() => {
              setLoader(false);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 2000);

          }
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getCosumerCoursesForUser()", err)
    }
  }

  //get all courses for footer for user
  const getCosumerCoursesForUserForFooter = (newCategorySid, newCategoryName) => {

    try {
      ReactGA.event({
        category: "Footer",
        action: "Clicked",
        label: newCategoryName, // optional
        value: newCategorySid, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
      const pageNo = currentPage;
      const pageSize = 6;
      const consumerSid = user?.sid;
      let payload = {
        "consumerSid": consumerSid
      };
      if (selectedOtherFilter !== undefined) {
        if (selectedOtherFilter !== null) {
          if (selectedOtherFilter.label === 'Free') {
            payload.paid = -1;
            payload.courseType = 0;
          }
          else if (selectedOtherFilter.label === 'Paid') {
            payload.paid = 1;
            payload.courseType = 0;
          }
          else if (selectedOtherFilter.label === 'Instructor-Led') {
            payload.paid = 0;
            payload.courseType = 1;
          }
          else if (selectedOtherFilter.label === 'Self Paced') {
            payload.paid = 0;
            payload.courseType = -1;
          }
        }
        else {
          payload.paid = 0;
          payload.courseType = 0;
          payload.priceFilter = 0;
          payload.ratingFilter = 0;
        }
      }

      if (selectedSortBy !== undefined) {
        if (selectedSortBy !== null) {
          if (selectedSortBy.label === 'Price- High to low') {
            payload.priceFilter = 1;
            payload.ratingFilter = 0;
          }
          else if (selectedSortBy.label === 'Price-Low to high') {
            payload.priceFilter = -1;
            payload.ratingFilter = 0;
          }
          else if (selectedSortBy.label === 'Rating- Low to high') {
            payload.priceFilter = 0;
            payload.ratingFilter = -1;
          }
          else if (selectedSortBy.label === 'Rating- High to low') {
            payload.priceFilter = 0;
            payload.ratingFilter = 1;
          }
        }
        else {
          payload.paid = 0;
          payload.courseType = 0;
          payload.priceFilter = 0;
          payload.ratingFilter = 0;
        }
      }

      if (categorySid.length !== 0) {
        payload.categorySid = categorySid;
      }
      if (newCategorySid !== null || newCategorySid !== undefined) {
        payload.categorySid = newCategorySid;
      }
      if (subCategorySid.length !== 0) {
        payload.subCategorySid = subCategorySid
      }
      RestService.getCosumerCoursesForUser(pageNo, pageSize, currency, payload).then(
        response => {
          if (response.status === 200) {
            setAllCourses(response.data);
            // setFilteredCourses(response.data);
            setPageSize(response.data.length !== 0 ? response.data[0].totalPages : 0);
            setCategoryDescription(response.data.length !== 0 ? response.data[0].categoryDescription : '');
            localStorage.removeItem('sid');
            localStorage.removeItem('name');
            setCategoryName(newCategoryName);
            setCategorySid(newCategorySid);
            setTimeout(() => {
              setLoader(false);
            }, 2000);

          }

        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getCosumerCourses()", err)
    }

  }


  //get sidebar for all categorises and subcategories
  const getAllCategoriesAndSubCategories = () => {
    try {
      // spinner.show();
      // const subCategorySid = subCategorySid;
      RestService.getAllCategoriesAndSubCategories().then(
        response => {
          if (response.status === 200) {
            const newCategory = response.data.map(category => ({
              value: category.categorySid,
              label: category.categoryName
            }))

            setCategories(newCategory);
            setCategoriesSubCategories(response.data);
            setTimeout(() => {
              setLoaderSideBar(false);
            }, 2000);
          }
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getAllCategoriesAndSubCategories()", err)
    }
  }



  //get all subcategories for categories for filter dropdown from sidebar
  const getAllConsumerCourseSubCategoriesFromSideBar = () => {
    try {
      // spinner.show();
      // const subCategorySid = subCategorySid;
      // const categorySid = selectedCategory;
      RestService.getAllConsumerCourseSubCategories(categorySid).then(
        response => {
          const reponseObject = response.data;
          ReactGA.event({
            category: "Sub category",
            action: "Changed",
            label: "Category sid", // optional
            value: categorySid, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
          const newSubCategory = Object.keys(reponseObject).map(category => ({
            value: category,
            label: reponseObject[category]
          }))
          const selectedCategory = {
            label: categoryName,
            value: categorySid
          }
          setSubCategories(newSubCategory);
          setSelectedCategory(selectedCategory);

        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getAllConsumerCourseSubCategories()", err)
    }
  }

  useEffect(() => {
    ReactGA.event({
      category: "On course Page",
      action: "hit",
      nonInteraction: true, // optional, true/false
    });

    getAllCategoriesAndSubCategories();
  }, []);

  useEffect(() => {
    if (categorySid.length !== 0) {
      getAllConsumerCourseSubCategoriesFromSideBar();
    }
    if ('sid' in localStorage && 'name' in localStorage) {
      const newCategorySid = localStorage.getItem('sid');
      const newCategoryName = localStorage.getItem('name');
      if ('user' in localStorage) {
        getCosumerCoursesForUserForFooter(newCategorySid, newCategoryName);
      }
      else {
        getCosumerCoursesForFooter(newCategorySid, newCategoryName);
      }
    }
    else {
      if ('user' in localStorage) {
        getCosumerCoursesForUser();
      }
      else {
        getCosumerCourses();
      }
    }

  }, [categorySid, subCategorySid, selectedSortBy, selectedOtherFilter, currentPage]);

  useEffect(() => {
    if (debouncedQuery.length >= 2) {
      // Assuming you have a function fetchSearchResults to call your API
      searchCourse();
    }
  }, [debouncedQuery]); // Effect runs on debouncedQuery change

  return (
    <>
      <Helmet>
        <title>Learnlytica | Explore Diverse Courses for Personalized Learning</title>
        <meta name="description" content="Explore a diverse range of courses in the Learnlytica Course Library. Discover personalized learning paths, diverse assessments, and real-time analytics for a transformative educational experience." />
        <meta name="keywords" content="Learnlytica, course library, personalized learning, diverse courses, assessments, learning paths, real-time analytics, education, skills improvement" />

      </Helmet>
      <div className='course-container'>
        <div className='row '>
          {/*  filter options */}
          <div className='col-lg-3 col-md-3 col-sm-12 mainsidebar mb-5 d-none d-lg-block ' >
            {loaderSideBar ?
              <SkeletonLoader skeletonCount={55} />
              :
              <div className='filter-sidebar-main p-5' style={{ height: "90%", overflowY: "scroll" }}>
                {
                  categoriesSubCategories?.map((category) => {
                    return (
                      <>
                        <div >
                          <div className='filter-sidebar-list' onClick={() => {
                            // if ('user' in localStorage) {

                            setCategorySid(category.categorySid);
                            setCategoryName(category.categoryName);
                            setSubCategorySid('');
                            setSelectedSubCategory(null);
                            // getCosumerCoursesForUser();

                            // getCosumerCoursesWithFilterCategoryForUser(category.categorySid);
                            // }
                            // else {
                            //   setCategoryName(category.categoryName);
                            //   setCategorySid(category.categorySid);
                            // getCosumerCourses();
                            // getCosumerCoursesWithFilterCategory(category.categorySid);
                            // setCategoryName(category.categoryName);
                            // }
                          }}>
                            {/* Job Role Path */}
                            {/* {category.categoryName} */}
                            {/* { */}

                            <DropdownItemforSidebar title={category.categoryName} theme="dark">
                              {
                                category.subCategories.map((subcategory) => {
                                  return (
                                    <div className='filter-sidebar-submenu '
                                      onClick={(e) => {
                                        // if ('user' in localStorage) {
                                        e.stopPropagation(); // Stop the event propagation
                                        // getCosumerCoursesWithFilterCategorySubCategoryForUser(category.categorySid, subcategory.subCategorySid);
                                        setCategoryName(category.categoryName);
                                        setCategorySid(category.categorySid);
                                        setSubCategorySid(subcategory.subCategorySid);
                                        setSelectedCategory({
                                          label: category.categoryName,
                                          value: category.categorySid
                                        })
                                        setSelectedSubCategory({
                                          label: subcategory.subCategoryName,
                                          value: subcategory.subCategorySid
                                        })
                                        // getCosumerCoursesForUser();
                                        // }
                                        // else {
                                        //   e.stopPropagation(); // Stop the event propagation
                                        //   // getCosumerCoursesWithFilterCategorySubCategory(category.categorySid, subcategory.subCategorySid);
                                        //   setSubCategoryName(subcategory.subCategoryName);
                                        //   // getCosumerCourses();
                                        // }

                                      }}>
                                      {ICN_COURSE_MENUE}  {subcategory.subCategoryName}
                                    </div>
                                  )
                                })
                              }
                            </DropdownItemforSidebar>
                            {/* } */}
                            {/* {category.categoryName.charAt(0) + category.categoryName.substring(1).toLowerCase()} */}
                          </div>
                        </div>
                      </>

                    )
                  })
                }

              </div>
            }
          </div>


          {/* course filter  */}

          <div className='col-lg-9 col-md-9 col-sm-12'>
            {/* serach */}

            <div className='container'>
              <div className='row  align-items-center' >
                <div className='col-md-8 col-sm-12 mt-4'>
                  <AnimationWrapper animationType="heading">
                    <div className='main-header-title ' onClick={Goback} style={{ fontSize: "28px", cursor: "pointer" }}>
                      {GO_BACK}
                      Explore All Courses</div></AnimationWrapper>
                  {/* Content for More Filter goes here */}
                  {/* <details id="details" onToggle={handleDetailsToggle}>
                      <summary className='morefilter'>{ICN_MORE_FILTER}<span>More Filter</span></summary>
                      <div onClick={() => handleFilterChange('PRICE')} style={{ cursor: 'pointer' }}>
                        Price
                      </div>
                      <div onClick={() => handleFilterChange('RATING')} style={{ cursor: 'pointer' }}>
                        Rating
                      </div>
                      {
                        filterPriceRating === 'PRICE' ?
                          <div>
                            
                            <div>
                              <input type="radio" id="priceLowToHigh" name="price" value="priceLowToHigh" onChange={(e) => callFilterApi(e.target.value)} />
                              <label for="dewey">Price Low to High</label>
                            </div>

                            <div>
                              <input type="radio" id="priceHighToLow" name="price" value="priceHighToLow" onChange={(e) => callFilterApi(e.target.value)} />
                              <label for="louie">Price High to Low</label>
                            </div>
                          </div>
                          :
                          filterPriceRating === 'RATING' &&
                          <div>
                          
                            <div>
                              <input type="radio" id="ratingLowToHigh" name="rating" value="ratingLowToHigh" onChange={(e) => callFilterApi(e.target.value)} />
                              <label for="dewey">Rating Low to High</label>
                            </div>

                            <div>
                              <input type="radio" id="ratingHighToLow" name="rating" value="ratingHighToLow" onChange={(e) => callFilterApi(e.target.value)} />
                              <label for="louie">Rating High to Low</label>
                            </div>
                          </div>

                      }


                    </details>  */}
                  {/* {
                      loader ?
                        <SkeletonLoader skeletonCount={0.5} />
                        :
                        <button className='morefilter' onClick={toggleVisibility}>{ICN_MORE_FILTER} More Filter</button>
                    } */}

                </div>
                <div className='col-md-4 col-sm-12 mt-5 d-none d-lg-block'>
                  {/* Content for Search goes here */}
                  {
                    loader ?
                      <SkeletonLoader skeletonCount={1} />
                      :
                      <>
                        <div class="input-group mt-4">
                          <input
                            type="text"
                            className="form-control applycoupn"
                            placeholder="Search Courses"
                            onChange={handleInputChange}
                          />
                          <button class="applycoupn-btn" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                            {ICN_SEARCH}
                          </button>
                        </div>
                        {/* Ensure this div is not affected by the d-flex class of the parent */}
                        {
                          searchTerm.length !== 0 &&

                          <div class="search-filter py-3 " style={{ position: 'absolute', backgroundColor: '#fff' }}>
                            {loading ? <div className=' p-5' style={{ display: "flex", justifyContent: "center" }}>
                              <Spinner
                                as="span"
                                animation="border"
                                size="md"
                                role="status"

                                style={{ border: "3px dotted black" }}

                              />

                            </div>
                              :

                              searchResults !== null &&
                              searchTerm.length !== 0 &&
                              Object.keys(searchResults).map((item) => (
                                <div className='searchlink' key={item} style={{ padding: '8px 10px', cursor: 'pointer' }}>
                                  <Link to={`/course/${item}`}>{searchResults[item]}</Link>

                                </div>
                              ))}
                            {/* {searchResults !== null &&
                          Object.keys(searchResults).length > 2 &&
                          (
                            <button onClick={viewAllSearchedCourses}>
                              Show More
                            </button>
                          )} */}
                          </div>
                        } 
                      </>

                  }

                </div>
              </div>
            </div>

            <div className='container'>
              <div className='library-header mb-5 mt-5'>

                <div className='row'>


                  <div className='main-header-title pt-3' style={{ color: "#084C2E" }}>{
                    loader ?
                      <SkeletonLoader skeletonCount={1} />

                      : categoryName.length !== 0 ? categoryName : "All Training"}</div>
                  <div className='main-header-sub-title pt-5' >
                    {
                      loader ?
                        <SkeletonLoader skeletonCount={1} />
                        :
                        categoryName.length !== 0 ?

                          categoryDescription
                          :
                          `Learnlytica offers a diverse tech education, featuring instructor-led training, self-paced courses, and hands-on cloud labs. Our curriculum spans software development to cloud computing, empowering learners at all levels to excel in the dynamic digital world.`
                    }
                  </div>

                </div>


              </div>
            </div>



            {/* EXPLORE MORE COURSE */}
            {/* {

                (categoryName.length !== 0 || categorySid.length !== 0 || subCategorySid.length !== 0 ||
                  selectedCategory !== undefined || selectedOtherFilter !== undefined || selectedSortBy !== undefined ||
                  selectedSubCategory !== undefined) &&


                <button className='reset' onClick={() => setReset(true)}>Reset</button>
              } */}
            <div className='container'>
              <div className='col-md-4 col-sm-12 mt-5 mobile-view'>
                {/* Content for Search goes here */}
                {
                  loader ?
                    <SkeletonLoader skeletonCount={1} />
                    :
                    <>
                      <div class="input-group mt-4">
                        <input
                          type="text"
                          className="form-control applycoupn"
                          placeholder="Search Courses"
                          onChange={handleInputChange}
                        />
                        <button class="applycoupn-btn" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                          {ICN_SEARCH}
                        </button>
                      </div>
                      {/* Ensure this div is not affected by the d-flex class of the parent */}
                      <div class="search-filter" style={{ width: "auto", backgroundColor: '#fff' }}>
                        {loading ? <div className='mt-auto p-5' style={{ display: "flex", justifyContent: "center" }}>
                          <Spinner
                            as="span"
                            animation="border"
                            size="md"
                            role="status"

                            style={{ border: "3px dotted black" }}

                          />

                        </div> :

                          searchResults !== null &&
                          searchTerm.length !== 0 &&
                          Object.keys(searchResults).map((item) => (
                            <div key={item} style={{ padding: '2px 10px', cursor: 'pointer' }}>
                              <Link to={`/course/${item}`}>{searchResults[item]}</Link>
                            </div>
                          ))}
                        {/* {searchResults !== null &&
                      Object.keys(searchResults).length > 2 &&
                      (
                        <button onClick={viewAllSearchedCourses}>
                          Show More
                        </button>
                      )} */}
                      </div>
                    </>
                }

              </div>
              <div className='row mt-5 mb-5' style={{ justifyContent: "space-between" }}>

                <div className='col-lg-3 col-md-3 col-sm-12 mt-5'>
                  <div class="form-group ">
                    <label className='label-class pb-1'>Other Filter</label>
                    {
                      loader ?
                        <SkeletonLoader skeletonCount={0.5} />
                        :
                        <Select
                          defaultValue="subCategories"
                          name="colors"
                          options={otherFilter}
                          onChange={handleOtherFilterChange}
                          value={selectedOtherFilter}
                          isClearable={true}
                          className="basic-multi-select "
                          classNamePrefix="SubCategories"


                        />
                    }
                  </div>


                </div>

                <div className='col-lg-3 col-md-3 col-sm-12 mt-5 '>
                  <div class="form-group ">
                    <label className='label-class pb-1'>Sort by</label>


                    {
                      loader ?
                        <SkeletonLoader skeletonCount={0.5} />
                        :
                        <Select
                          defaultValue="Select"
                          name="colors"
                          options={sortby}
                          onChange={handleSortByChange}
                          value={selectedSortBy}
                          isClearable={true}
                          className="basic-multi-select "
                          classNamePrefix="select"

                        />
                    }


                  </div>


                </div>


                <div className='col-lg-3 col-md-3 col-sm-12 mt-5'>
                  <div class="form-group ">
                    <label className='label-class pb-1'>Filter by Categories</label>
                    {
                      loader ?
                        <SkeletonLoader skeletonCount={0.5} />
                        :
                        <Select
                          defaultValue="Categories"
                          name="colors"
                          options={categories}
                          onChange={handleCategoryChange}
                          value={selectedCategory}
                          isClearable={true}
                          className="basic-multi-select "
                          classNamePrefix="Categories"

                        />
                    }
                  </div>


                </div>



                <div className='col-lg-3 col-md-3 col-sm-12 mt-5'>
                  <div class="form-group ">
                    <label className='label-class pb-1'>Filter by SubCategories</label>
                    {
                      loader ?
                        <SkeletonLoader skeletonCount={0.5} />
                        :
                        <Select
                          defaultValue="subCategories"
                          name="colors"
                          options={subCategories}
                          onChange={handleSubCategoryChange}
                          value={selectedSubCategory}
                          isClearable={true}
                          isDisabled={!selectedCategory}
                          className="basic-multi-select "
                          classNamePrefix="SubCategories"


                        />
                    }
                  </div>


                </div>

                {/* <div className='col-lg-1 col-md-1 col-sm-12 mt-5 mb-5'>
                    <button className='reset mt-4' style={{ float: "inline-end" }} onClick={() => setReset(true)}>Reset</button>
                  </div> */}





              </div>

            </div>

            {allCourses.length === 0 ? (
              <div className='layout'>
                <div className='container'>
                  <div className='ph  text-center' style={{ fontSize: "25px" }}>
                    Courses being curated by our delivery team will be available soon.
                  </div>

                </div>
              </div>
            ) : (
              <div class="row mt-5 mb-5">

                {
                  allCourses.map((item) => {
                    return (
                      <div class="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">
                        <div class="card py-1 px-5 h-100 " >

                          {/* {item.headerimg} */}
                          <AnimationWrapper animationType="card">
                            <div class="card-body d-flex flex-column">
                              {
                                loader ?
                                  <SkeletonLoader skeletonCount={1} />
                                  :
                                  item.courseType === 'INSTRUCTOR_LED' &&
                                  <>
                                    <div className="ribbon-2 ">

                                      <div className='instled-date pb-1'>{loader ?
                                        <SkeletonLoader skeletonCount={1} />
                                        : item?.duration !== "NO_EXPIRY" && item.duration} </div>

                                    </div>


                                  </>
                              }

                              <h4 class="card-title mt-5 pt-4" data-tooltip-id="AssessmentDownload" data-tooltip-content={item.courseName}>{
                                loader ?
                                  <SkeletonLoader skeletonCount={1} />
                                  :
                                  item.courseName
                              }</h4>
                              <Tooltip
                                id="AssessmentDownload"
                                // data-tooltip-place="bottom"
                                // variant="info"
                                place='bottom'
                                style={{
                                  width: "80%", fontFamily: "Inter"
                                }}
                              />
                              {
                                loader ?
                                  <SkeletonLoader skeletonCount={1} />
                                  :

                                  <div className='d-flex flex-wrap pt-3 pb-3 gap-2 ' >
                                    {/* <button className='card-top-btn flex-grow-1 mb-2 mr-1' >{item.categoryName}</button>
                                      <button className='card-top-btn flex-grow-1 mb-2 mr-1' >{item.subCategoryName}</button> */}
                                    {
                                      item.tags?.map((tag, index) => {
                                        return (
                                          <div key={index} className='card-top-btn flex-grow-1 mb-2 mr-1'

                                          >{tag}</div>
                                        )
                                      })
                                    }

                                  </div>

                              }
                              <div className='d-flex flex-wrap gap-3 mb-4 mt-5' style={{ justifyContent: "space-between" }}>
                                <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_LECTURE}</span><span className='mt-5'>{
                                  loader ?
                                    <SkeletonLoader skeletonCount={1} />
                                    :
                                    item.totalContent} Lectures</span></div>
                                <div className='total card-subtitle'> <span className='mx-2'>{ICN_TOTAL_TIME}</span> <span className='mt-5'>{
                                  loader ?
                                    <SkeletonLoader skeletonCount={1} />
                                    : item.totalDuration} Total Hours</span></div>
                              </div>
                              <p class="card-text gap-2"><span >{ICN_LANGUAGE}</span>{
                                loader ?
                                  <SkeletonLoader skeletonCount={1} />
                                  : item !== undefined && item?.languages?.toString()} </p>

                              <div className="course-card-h"> {currency === 'INR' ? '₹' : '$'}{loader ?
                                <SkeletonLoader skeletonCount={1} />
                                :
                                currency === 'INR' ?
                                  Math.floor(item?.price)
                                  :
                                  Math.floor(item?.internationalCoursePrice)
                              } </div>

                              {
                                loader ?
                                  <SkeletonLoader skeletonCount={1} />

                                  :

                                  <Link to={`/course/${item.courseSid}`} class="card-view-course mt-auto ">View Course {ICN_NEXT}</Link>

                              }


                            </div></AnimationWrapper>
                        </div>
                      </div>
                    )
                  })
                }
                {pageSize > 1 &&
                  <div style={stackContainerStyle}>
                    <Stack spacing={3} direction="row" alignItems="center" justifyContent="space-between" width="100%">
                      <button className='page_button' style={{ float: "left" }} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage <= 1}
                      >{PREV_PAGINATION_ICON} Previous</button>

                      <Pagination count={pageSize} page={currentPage} onChange={handlePageChange}
                        size='large' />
                      <button className='page_button' style={{ float: "inline-end" }} onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === pageSize}>Next {NEXT_PAGINATION_ICON}</button>

                    </Stack>


                  </div>
                }


              </div>

            )}







            {/* {
                  <nav aria-label="Page navigation example mb-5">
                    <ul className="pagination">
                      {
                        pageSize?.map((pageNo, index) => (
                          <li key={index} className="page-item">
                            <a
                              className="page-link"
                              href="#"
                              onClick={() => setCurrentPage(index + 1)}
                            >
                              {index + 1}
                            </a>
                          </li>
                        ))
                      }
                    </ul>
                  </nav>
                } */}




            {/* <div className='container'>
              <div style={stackContainerStyle}>
                <Stack spacing={3} direction="row" alignItems="center" justifyContent="space-between" width="100%">
                  <button className='page_button' style={{ float: "left" }} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage <= 1}
                  >{PREV_PAGINATION_ICON} Previous</button>

                  <Pagination count={pageSize} page={currentPage} onChange={handlePageChange}
                    size='large' />
                  <button className='page_button' style={{ float: "inline-end" }} onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === pageSize}>Next {NEXT_PAGINATION_ICON}</button>

                </Stack>


              </div>
            </div> */}

          </div>



        </div>
      </div>

    </>
  )
}

export default Library
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import AllCourse from './Screens/Courses/AllCourse';
import LandingPage from './Screens/LandingPage/LandingPage';
import { Header } from './Components/Common/Header';
import Footer from './Components/Common/Footer';
import SpecificCourse from './Screens/Courses/SpecificCourse';
import Library from './Screens/Courses/Library/Library';
import Legal from './Screens/Legal/Legal';
import TermsAndConditions from './Screens/Legal/TermsAndConditions';
import Privicypolicy from './Screens/Legal/Privicypolicy';
import CancellationAndRefund from './Screens/Legal/CancellationAndRefund';
import ShippingPolacy from './Screens/Legal/ShippingPolacy';
import Contact from './Screens/Legal/Contact';
import SignUp from './Screens/Auth/SignUp';
import Login from './Screens/Auth/Login';
import ForgotPassword from './Screens/Auth/ForgotPassword';
import { CartContextProvider } from './Store/CartContext';
import ScrollToTop from './Components/Common/ScrollToTop';
import ExploreCatalog from './Screens/CorporateTraining/ExploreCatalog';
import Sandbox from './Screens/Sandbox/Sandbox';
import Contactus from './Screens/Contactus/Contactus';
import OrderHistory from './Screens/Auth/User/OrderHistory';
import AccountSettings from './Screens/Auth/User/Settings/AccountSettings';
import ResetLink from './Screens/Auth/ResetLink';
import MyCourses from "./Screens/Auth/User/MyCourses";
import PrivateRoute from './Components/Common/PrivateRoute/PrivateRoute';
import Post from "./Screens/Blog/Post"
import FullPost from './Screens/Blog/FullPost';
import ExploreCareer from './Components/Common/Workwithus/ExploreCareer';
import Partnerwithus from './Components/Common/Workwithus/Partnerwithus';
import BecomeTrainer from './Components/Common/Workwithus/BecomeTrainer';
import LogoutUser from './Screens/Auth/LogoutUser';
import ReactGA from "react-ga4";
import Whatsapp from './Components/Common/Whatsapp/Whatsapp';

ReactGA.initialize("G-T4KKDM62E4");
function FooterWithControl() {
  // const { isSignUpRoute, setIsSignUpRoute } = useContext(CartContext);
  const [isSignUpRoute, setIsSignUpRoute] = useState(true);
  const location = useLocation();
  const setPasswordLink = location.pathname.split('/')[1];

  useEffect(() => {
    // Update showFooter based on the route
    if (location.pathname === '/signup' ||
      location.pathname === '/login' ||
      location.pathname === '/forgotpassword' ||
      location.pathname === '/settings' ||
      setPasswordLink === 'forgot-password') {
      setIsSignUpRoute(false);
    } else {
      setIsSignUpRoute(true);
    }
  }, [location]);

  return isSignUpRoute ? <Footer /> : null;
}

function App() {
  // const isSignUpRoute = window.location.pathname.includes('/signup') || window.location.pathname.includes('/login') || window.location.pathname.includes('/forgotpassword') || window.location.pathname.includes('/settings');
  const isLoggedIn = 'REACTAPP.TOKEN' in localStorage && 'user' in localStorage;
  return (
    <>
 <Whatsapp phone={9870643070}/>
      <CartContextProvider>
        <Router>
          <ScrollToTop />

          <Header />



          {/* <PercentageScrollBar/>
<GoToTopButton/>  */}
          <Routes>

            {/* <Route path='/signup' element={<SignUp />} /> */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/all-courses" element={<AllCourse />} />
            <Route path="/course-library" element={<Library />} />
            {/* <Route path="/course/*" element={<SpecificCourse />} /> */}
            <Route path="/course/:courseid" element={<SpecificCourse />} />
            <Route path="/training" element={<ExploreCatalog />} />
            <Route path="/sandbox" element={<Sandbox />} />
            <Route path="/contactus" element={<Contactus />} />
            <Route path="/purchase-history" element={<PrivateRoute element={<OrderHistory />} />} />
            <Route path="/logout" element={<PrivateRoute element={<LogoutUser />} />} />

            {/* <Route path ="/settings" element ={<AccountSettings/>}/> */}
            <Route path="/blog" element={<Post />} />
            <Route path="/blog/:id/:category" element={<FullPost />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/legal/terms" element={<TermsAndConditions />} />
            <Route path="/legal/privacy" element={<Privicypolicy />} />
            <Route path="/legal/Cancellation-and-Refund-Policy" element={<CancellationAndRefund />} />
            <Route path="/legal/Shipping-and-Delivery-Policy" element={<ShippingPolacy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/explore-career" element={<ExploreCareer />} />
            <Route path="/partner-with-us" element={<Partnerwithus />} />
            <Route path="/become-trainer" element={<BecomeTrainer />} />
            <Route path="/mycourses" element={<PrivateRoute element={<MyCourses />} />} />
            <Route
              path="/signup"
              element={
                <React.Fragment>
                  <SignUp isLoggedIn={isLoggedIn} />
                  {/* Don't render Footer when SignUp component is displayed */}
                </React.Fragment>
              }

            />
            <Route
              path="/login"
              element={
                <React.Fragment>
                  <Login isLoggedIn={isLoggedIn} />
                  {/* Don't render Footer when SignUp component is displayed */}
                </React.Fragment>
              }

            />

            <Route
              path="/forgotpassword"
              element={
                <React.Fragment>
                  <ResetLink isLoggedIn={isLoggedIn} />
                  {/* Don't render Footer when SignUp component is displayed */}
                </React.Fragment>
              }

            />

            <Route
              path="/settings"
              element={
                <React.Fragment>
                  <PrivateRoute element={<AccountSettings />} />
                  {/* Don't render Footer when SignUp component is displayed */}
                </React.Fragment>
              }

            />
            <Route
              path="/forgot-password/:uuid/:timestamp"
              element={
                <React.Fragment>
                  <ForgotPassword isLoggedIn={isLoggedIn} />
                  {/* Don't render Footer when SignUp component is displayed */}
                </React.Fragment>
              }

            />


          </Routes>
          <FooterWithControl />
          {/* {!isSignUpRoute && <Footer />} */}
        </Router>
      </CartContextProvider>



    </>
  );
}

export default App;

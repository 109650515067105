import React, { useState } from 'react';
import RestService from '../../Services/api.service';
import './Comments.css';
import AllModal from './AllModal';
import SignupForCart from '../../Screens/Auth/SignupForCart';

function NewCommentForm({ blogSlug, onCommentAdded }) {
  const [commentText, setCommentText] = useState('');
  const [showSignUp, setShowSignUp] = useState(false);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);

  const handleChangeCommentText = (e) => {
    if ('user' in localStorage) {
      if (!hasStartedTyping) setHasStartedTyping(true);
      setCommentText(e.target.value);
    }
    else {
      setShowSignUp(true);
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let consumerSid1 = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : '';

    const payload = {
      text: commentText,
      consumerSid: consumerSid1.sid
    }

    try {
      const response = await RestService.commentOnBlog(blogSlug, payload);
      if (response.status === 200) {
        setCommentText('');
        onCommentAdded(response.data.newComment);
        // window.location.reload(true);

      }
    } catch (err) {
      console.error("Error submitting new comment: ", err);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="new-comment-form maincomentdiv">
        <div className='row' style={{ alignItems: "center", justifyContent: "center" }}>
          <div className='col-md-10'>
            <textarea
              className='comment-textarea'
              value={commentText}
              onChange={handleChangeCommentText}
              placeholder="Write your comment here..."
              required
            />
            {hasStartedTyping &&
              <p className="error-message">
                {`${Math.max(0, 500 - commentText.length)} characters left`}
              </p>
            }

          </div>
          < div className='col-md-2'>

            <button className="btn-send" style={{
              opacity: (commentText.length > 499 || commentText.length === 0) ? 0.5 : 1,
              cursor: (commentText.length >= 500 || commentText.length === 0) ? 'not-allowed' : 'pointer'
            }} disabled={commentText.length >= 500 || commentText.length === 0} type="submit">Send</button>

          </div>

        </div>
      </form>
      {
        showSignUp &&
        <AllModal show={showSignUp} setShow={setShowSignUp} headerTitle="" >
          <>
            <SignupForCart setShowSignUp={setShowSignUp} />
          </>
        </AllModal>

      }
    </>

  );
}

export default NewCommentForm;

import React, { useEffect, useState } from 'react';
import { ICN_HEADER_LOGO1, ICN_HEADER_LOGO2, ICN_HEADER_LOGO3, ICN_HEADER_LOGO4, ICN_HEADER_LOGO13, ICN_HEADER_LOGO14, ICN_HEADER_LOGO15, ICN_HEADER_LOGO16, IMG_HEADER, IMG_C1, IMG_C2, IMG_C3, IMG_C4, IMG_C5, IMG_C6, IMG_C8, IMG_C7, IMG_FEATURE, IMG_COMMENTS, ICN_HANDSON, IMG_HANDSON, IMG_COST_CONTROL, ICN_COST_CONTROL, IMG_TRAINING_FEATURE, ICN_TRAINING_ANALYTICS } from '../../Components/Common/Icon'
import Aos from 'aos';
import 'aos/dist/aos.css';
import C1 from "../../Assets/Images/Png-30.png"
import C2 from "../../Assets/Images/Png-31.png"
import C3 from "../../Assets/Images/Png-33.png"
import C4 from "../../Assets/Images/Png-34.png"
import C5 from "../../Assets/Images/Png-35.png"
import C6 from "../../Assets/Images/Png-36.png"
import C7 from "../../Assets/Images/Png-37.png"
import C8 from "../../Assets/Images/Png-38.png"
import Readytoexplore from "../../Assets/Images/readytoexplore.png"
import LoadingSpinner from '../../Components/Common/LoadingSpinner';
import SkeletonLoader from '../../Components/Common/SkeletonLoader';
import { Link } from 'react-router-dom';
import AnimationWrapper  from '../../Components/Common/Animation Ref/AnimationWrapper';
import VideoComponent from '../../Components/Common/VideoComponent';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';



 
const LandingPage = () => {
  const [showmore, setShowMore] =useState(false)
  const [showmore1, setShowMore1] =useState(false)
  const [showmore2, setShowMore2] =useState(false)



  const [loader, setLoader] = useState(true);
  useEffect(() => {
    ReactGA.event({
      category: "Landing Page",
      action: "hit",
      nonInteraction: true, // optional, true/false
    });
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  }, []);
  return (
    <>
<Helmet>
<title>Learnlytica | Transform minds with brilliance, experience, and innovation. </title>
</Helmet>
      <div className='layout'>
        <div className='container landingpage-header '>
          <div className='row parrent' style={{ alignItems: "center" }} >
            <div className='col-lg-7 col-md-7 col-sm-12'>
              {

                loader ?
                  <SkeletonLoader skeletonCount={1} />
                  :
                  <AnimationWrapper animationType="heading">
                  <div className='ph'>
                    High Impact  Trainings &
                    Labs  <span className='pt-0 pb-0 px-5' style={{
                      borderRadius: "100px",
                      background: "#FFC2EA"
                    }}>Effortlessly</span>
                  </div></AnimationWrapper>
              }

              {
                loader ?
                  <SkeletonLoader skeletonCount={3} />
                  :
                  <AnimationWrapper animationType="subHeading">
                  <div className='pt mt-5'>
                     
                     Learnlytica not only facilitates high-impact trainings and labs effortlessly but

                    also serves as a bridge, ensuring a smooth transition from your current skill set to
                     the desired proficiency level. Elevate your learning journey seamlessly with us.

                  </div></AnimationWrapper>
              }


              {loader ?
                <SkeletonLoader skeletonCount={1.5} />
                :
                <AnimationWrapper animationType="button">
                <div className='mt-5 mobile-btn'>
                  <Link  to="/course-library"class="btn-primary mobile-btn" style={{ padding: "10px 18px" }}>Explore all Courses</Link>
                </div></AnimationWrapper>
              }
            </div>

            <div className='col-lg-5 col-md-5 col-sm-12' >
              {
                loader ?
                  <SkeletonLoader skeletonCount={5} />
                  :
                  IMG_HEADER}
            </div>
          </div>

        </div>

        {/* client */}
        <AnimationWrapper animationType="heading"><div className='section-header'>Trusted by Leading Organizations</div></AnimationWrapper>
       <div className='container'>
       <div class="logos">
          <div class="logos-slide">
            <img src={C1} alt='icon' />
            <img src={C2} alt='icon' />
            <img src={C3} alt='icon' />
            <img src={C4} alt='icon' />
            <img src={C5} alt='icon' />
            <img src={C6} alt='icon' />
            <img src={C7} alt='icon' />
            <img src={C8} alt='icon' />
            
            <img src={C1} alt='icon' />
            <img src={C2} alt='icon' />
            <img src={C3} alt='icon' />
            <img src={C4} alt='icon' />
            <img src={C5} alt='icon' />
            <img src={C6} alt='icon' />
            <img src={C7} alt='icon' />
            <img src={C8} alt='icon' />
          </div>

          


        </div>
       </div>

        {/* feature */}

        <div className='container'>
          <div className='subheading'>Features</div>
          <AnimationWrapper animationType="heading"> <div className='ph mt-5' style={{ fontWeight: "500", textAlign: "center" }}>
            Make Effective Virtual
            <div>Experiences The Easy Way</div>
          </div></AnimationWrapper>
          <AnimationWrapper animationType="subHeading">
          <div className='text-center mt-3'>
            <div className='text '>Learnlytica's approach empowers you to effortlessly shape impactful virtual training experiences.

            </div>
            <div className='text'>With our streamlined process, crafting effective and engaging training sessions becomes second nature.
           </div>
           <div className='text '>  Our platform ensures a seamless and effective journey,facilitating skill  development and optimizing the</div>
          <div className='text'>training process for lasting impact.</div>
          </div></AnimationWrapper>
        </div>

       




        {/* fast setup */}
        
 {/* class ="parrent"*/}
      <div className='layout'>
      <div className='container'>
      <div className='row '>
        <div className='col-md-6 col-lg-6 col-sm-12'>
          <div className='d-flex align-items-center' >
            <div className='icn-bc'>{IMG_COMMENTS}</div>

            <AnimationWrapper animationType="heading">  <h5 className='mx-5'>FAST SETUP</h5> </AnimationWrapper>
          </div>
          <AnimationWrapper animationType="heading">   <h4 className='mt-3'>Launch Configured Labs Effortlessly </h4></AnimationWrapper>
          <AnimationWrapper animationType="subHeading">    <p className='mt-3'>Learnlytica's labs are facilitated through the automatic creation of lab environments, driven by our advanced lab engine. This engine not only establishes the necessary environments swiftly but also actively monitors user activity. This real-time monitoring allows for the provision of constructive feedback, enabling a more in-depth analysis of performance. With Learnlytica, experience labs that are dynamically created, expertly monitored, and designed for enhanced learning and analysis. </p></AnimationWrapper>
          <AnimationWrapper animationType="subHeading"> 
          <div className='sectionpoint mt-3 mb-3'animationType="listItem">
            <ul>
              <li>Quick and auto setup 
              </li>
              <li>Robust Environment 
              </li>
              <li>Guided Steps and 24/7 Support 
              </li>
              <li>Task Completion report for every lab </li>

            </ul>
          </div> </AnimationWrapper>

        </div>
        <div className='col-md-6 col-lg-6 col-sm-12 ' style={{margin:"auto"}}>
        <VideoComponent source="https://gnosislabs.s3.ap-south-1.amazonaws.com/newgif/h1_480p.mp4"/>

          
        </div>

      </div>
    </div>
      </div>

        {/* Hands on experience */}

      <div className='layout'>
      <div className='container'>
      <div className='row mobile-flxdirectionnew '>
        <div className='col-md-6 col-lg-6 col-sm-12 ' >
        <VideoComponent source="https://gnosislabs.s3.ap-south-1.amazonaws.com/newgif/h2_480p.mp4"/>
        </div>
        <div className='col-md-6 col-lg-6 col-sm-12'>
          <div className='d-flex align-items-center' >
            <div className='icn-bc'>{ICN_HANDSON}</div>
            <AnimationWrapper animationType="heading">   <h5 className='mx-5'>HANDS-ON EXPERIENCES
            </h5> </AnimationWrapper> 
          </div>
          <AnimationWrapper animationType="heading">  <h4 className='mt-3'>Empowering Tomorrow's Project Leaders: Train with Case Studies, Solve with Skill, Deploy with Confidence!
          </h4> </AnimationWrapper> 
          <AnimationWrapper animationType="subHeading">   <p className='mt-3 '>Unlock practical skills with our case-study-centric approach, mastering real-world problem-solving. From quick adaptation to seamless project deployment, our dynamic learning ensures confidence in every project scenario </p> </AnimationWrapper >
          <AnimationWrapper animationType="listItem"> 
          <div className='sectionpoint mt-3 mb-3'>
            <ul>
              <li>Case-Study Mastery: Immerse learners in real-world scenarios for hands-on problem-solving. 

              </li>
              <li>Practical Approach: Foster quick adaptability by tackling challenges mirroring project environments. 

              </li>
              <li>Rapid Deployment Skills: Equip learners with solutions-ready expertise for seamless project integration. 

              </li>
              <li>Dynamic Learning: Navigate complexities through case studies, honing skills applicable to diverse projects. </li>
<li>Confidence Assurance: Gain the confidence to deploy learned solutions swiftly, ensuring project success. </li>
            </ul>
          </div>
          </AnimationWrapper> 

        </div>


      </div>
    </div>
      </div>

        {/* cost control */}
      <div className='layout'>
      <div className='container'>
      <div className='row '>
        <div className='col-md-6 col-lg-6 col-sm-12'>
          <div className='d-flex align-items-center' >
            <div className='icn-bc'>{ICN_COST_CONTROL}</div>
            <AnimationWrapper animationType="heading">    <h5 className='mx-5'>COST CONTROL</h5> </AnimationWrapper>
          </div>
          <AnimationWrapper animationType="heading">    <h4 className='mt-3'>Optimize training effectiveness at the most competitive cost.
          </h4> </AnimationWrapper>
          <AnimationWrapper animationType="subHeading">     <p className='mt-3 '>Maximize training impact without exceeding your budget. Learnlytica's approach ensures cost-effective solutions for top-tier training. Achieve optimal learning outcomes at the best possible cost.
          </p></AnimationWrapper> 
          <AnimationWrapper animationType="listItem"> 
          <div className='sectionpoint mt-3 mb-3'>
            <ul>
              <li>Maximize training impact without exceeding your budget. Learnlytica's approach ensures cost-effective solutions for top-tier training. Achieve optimal learning outcomes at the best possible cost.
              </li>
              <li>Efficient Resource Management: Learnlytica enables efficient allocation of resources, allowing you to make the most of your training budget without compromising quality. 
{!showmore &&<div onClick={()=>  setShowMore(!showmore)} className='card-view-course'>Show more</div>}
              </li>
              {
                showmore && 
                <>
                 <li>Customizable Training Packages: Choose training packages that align with your requirements, providing a cost-effective solution tailored to your organization's needs. 

</li>
<li>Regular Cost Audits: We conduct regular cost audits to identify potential areas for optimization, ensuring that your training initiatives remain cost-effective over time. </li>
<li>Scalability and Affordability: With Learnlytica, scale your training programs as needed without a proportional increase in costs, maintaining affordability as your organization grows. </li>

                
                </>
              }
              {showmore &&<div onClick={()=>  setShowMore(!showmore)} className='card-view-course'>Show less</div>}

             
            </ul>
          </div>
          </AnimationWrapper> 

        </div>
        <div className='col-md-6 col-lg-6 col-sm-12' >
        <VideoComponent source="https://gnosislabs.s3.ap-south-1.amazonaws.com/newgif/h3_480p.mp4"/>
        </div>

      </div>
    </div>
      </div>

        {/* analytics and reporting */}

       <div className='layout'>
       <div className='container'>
       <div className='row  mobile-flxdirectionnew '>
         <div className='col-md-6 col-lg-6 col-sm-12' >
         <VideoComponent source="https://gnosislabs.s3.ap-south-1.amazonaws.com/newgif/h4_480p.mp4"/>
         </div>
         <div className='col-md-6 col-lg-6 col-sm-12'>
           <div className='d-flex align-items-center' >
             <div className='icn-bc'>{ICN_TRAINING_ANALYTICS}</div>
             <AnimationWrapper animationType="heading">    <h5 className='mx-5'>ANALYTICS & REPORTING
             </h5> </AnimationWrapper> 
           </div>
           <AnimationWrapper animationType="heading">   <h4 className='mt-3'>Elevate Your Training with Monitored Excellence, Actionable Insights, Continuous Improvement
           </h4></AnimationWrapper> 
           <AnimationWrapper animationType="subHeading">    <p className='mt-3 '>Experience training like never before with Learnlytica's cutting-edge approach. Our platform ensures meticulous monitoring of every learning journey, providing real-time insights for continuous enhancement. From dynamic analytics to personalized feedback, we empower your team to thrive with actionable intelligence. Elevate your training initiatives with us for a future of continual improvement. 
           </p> </AnimationWrapper>
           <AnimationWrapper animationType="listItem"> 
           <div className='sectionpoint mt-3 mb-3'>
             <ul>
               <li>Real-time Monitoring: Learnlytica employs advanced real-time monitoring tools to track learner progress and engagement, ensuring a dynamic understanding of training effectiveness. 

               </li>
               {!showmore1 &&<div onClick={()=>  setShowMore1(!showmore1)} className='card-view-course'>Show more</div>}
               {
       showmore1 &&  
       
       <>
               <li>Data-Driven Analytics: Our platform leverages robust analytics to transform training data into actionable insights, enabling informed decision-making for continuous improvement. 

               </li>
              
      
                <li>Performance Metrics: Gain detailed performance metrics on individual and group levels, allowing for targeted interventions and personalized learning paths. 

</li>
<li>Feedback Mechanisms: Incorporate feedback mechanisms that facilitate learner input and instructor assessments, fostering a collaborative environment for improvement. </li>
<li></li>
{showmore1 &&<div onClick={()=>  setShowMore1(!showmore1)} className='card-view-course'>Show less</div>}
       </>
      }
             </ul>
           </div>
           </AnimationWrapper>
         </div>


       </div>
     </div>
       </div>

        {/* ready to explore */}

      <div className='layout'> 
        <div className='container'>
          <div className='row readytoexplore' >
            <div className='col-xl-7 col-lg-7 col-md-7 col-sm-12 leftside ' style={{ padding: "90px 50px" }}>
            <AnimationWrapper animationType="heading">
              <div className='exploretext mobile-text '>
                Ready to Explore?
              </div>  </AnimationWrapper>
              <AnimationWrapper animationType="subHeading"> 
              <div className='exploretextsub mobile-text'>
                Request a Demo or Start Your Journey Now!
              </div> </AnimationWrapper>
              {/* <button className='btn_getintouch mt-5'>Get in Touch</button> */}
              <AnimationWrapper animationType="button"> 
             <div className='mt-5'>
             <Link  to="/contactus"class=" btn_getintouch ">Get in Touch</Link>
             </div> </AnimationWrapper>
            </div>
            <div className='col-xl-5 col-lg-5 col-md-5 col-sm-12' style={{ padding: " 0px" }} >
              <img src={Readytoexplore} className='img-fluid get-image' />
            </div>

          </div>
        </div>
      </div>






      </div>
    </>
  )
}

export default LandingPage
import React from 'react'
import { ICN_HEADER_EXPAND, ICN_LINK, ICN_NEXT } from '../../Components/Common/Icon'
import { Link } from 'react-router-dom'

const Legal = () => {
  return (
    <>

<div className='filter-sidebar-main p-5 h-100'>
    <div className=' d-flex justify-content-between align-items-center' >
<div className='filter-sidebar-list'>
<Link to="/legal/terms">Terms and Conditions</Link>
</div>
{/* <div >
{ICN_NEXT}
</div> */}
    </div>
    <div className='  d-flex justify-content-between align-items-center' >
<div className='filter-sidebar-list'>
<Link to="/contact">Contact</Link>
</div>
{/* <div >
{ICN_NEXT}
</div> */}
    </div>


    <div className=' d-flex justify-content-between align-items-center'  style={{cursor:"pointer"}}>
<div className='filter-sidebar-list'>
<Link to="/legal/Cancellation-and-Refund-Policy">Cancellation and Refund Policy</Link>
</div>

{/* <div >
{ICN_NEXT}
</div> */}


    </div>
    <div className=' d-flex justify-content-between align-items-center'  style={{cursor:"pointer"}}>
<div className='filter-sidebar-list'>
<Link to="/legal/privacy">Privacy Policy</Link>
</div>

{/* <div >
{ICN_NEXT}
</div> */}


    </div>
    <div className=' d-flex justify-content-between align-items-center'  style={{cursor:"pointer"}}>
<div className='filter-sidebar-list'>
<Link to="/legal/Shipping-and-Delivery-Policy">Shipping and Delivery Policy</Link>
</div>

{/* <div >
{ICN_NEXT}
</div> */}


    </div>

   
   

   
   


</div>

    </>
  )
}

export default Legal
import React from 'react'
import Legal from './Legal'

const ShippingPolacy = () => {
  return (
    <>

<div className='course-container'>
<div className='row '>
    {/*  filter options */}
<div className='col-lg-3 col-md-3 col-sm-12  mb-5'>
    <Legal/>
    </div>

    <div className='col-lg-9 col-md-9 col-sm-12  mb-5' >
    <div className='course-section'>
        <div className='row container'>
            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
            <div className='learningpt-header' >Contact</div>
            <div className='requirment pt-5'>Last updated on Jan 2nd 2024</div>
            

            <div className='requirment pt-5'>
            Shipping is not applicable for business.
               </div>


          
            </div>

        </div>

    </div>



    
    
    
    </div>

    </div>
</div>

    </>
  )
}

export default ShippingPolacy
import React, { useState, useEffect } from 'react';
import { ICN_CH1, ICN_CH2, ICN_CH3, IMG_ALL_COURSE_HEADER, IMG_COST_CONTROL, IMG_EXPLORE_HEADER, IMG_FEATURE, IMG_HANDSON, IMG_TRAINING_FEATURE } from '../../Components/Common/Icon';
import { Link } from 'react-router-dom';
import "./Corporate.css";
import Readytoexplore from "../../Assets/Images/readytoexplore.png";
import SkeletonLoader from '../../Components/Common/SkeletonLoader';
import AnimationWrapper  from '../../Components/Common/Animation Ref/AnimationWrapper';
import VideoComponent from '../../Components/Common/VideoComponent';

const ExploreCatalog = () => {
  const styles = {
    color: "#F9F5FF"
  };

  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  }, []);

  return (
    <>
      <div className=' allcourse-header' >
        <div className='container'>
          <div className='row parrent' style={{ alignItems: "center" }} >
            <div className='col-lg-12 col-md-12 col-sm-12 mt-5 mb-5'>
              {
                loader ?
                  <SkeletonLoader skeletonCount={1} />
                  :
                  <AnimationWrapper animationType="heading">
                  <div className='ph' style={styles} >
                    Give your trainers the <span style={{ textDecoration: "underline" }}>Superpowers  </span>
                    <div>they need to <span style={{ textDecoration: "underline" }}>succeed</span> </div>

                  </div>
                  </AnimationWrapper>
              }
              {
                loader ?
                  <SkeletonLoader skeletonCount={0.7} />
                  :
                  <AnimationWrapper animationType="subHeading">
                  <div className='pt mt-5 pb-5' style={{ color: "#EAECF0" }}>
                    — Our flexible, scalable virtual training software enables you to easily deliver memorable, hands-on

                    <div>experiences that students will love (and tell their colleagues about). </div>


                  </div>
                  </AnimationWrapper>
              }

              {
                loader ?
                  <SkeletonLoader skeletonCount={0.3} />
                  :
                  <AnimationWrapper animationType="button"><div className='mt-5 mb-5  mobile-btn'>
                    <Link to="/contactus" class="library-button mobile-btn">Book a Demo</Link>
                  </div></AnimationWrapper>
              }
            </div>

            {
              loader ?
                <SkeletonLoader skeletonCount={5} />
                :
                <div className='col-lg-12 col-md-12 col-sm-12 mt-5' >
                  {IMG_EXPLORE_HEADER}
                </div>
            }

          </div>

        </div>
      </div>





    <div className='layout'>
    <div className='course-layout'>
    <AnimationWrapper animationType="heading">
      <div className='section-heading' >Your virtual training unicorn does exist</div></AnimationWrapper>
      <AnimationWrapper animationType="subHeading"> <div className='section-sub-heading'>
        <p >
          The world of online training and learning has evolved – and your customers’
        </p>
        <p >expectations and training preferences are constantly changing.</p>
      </div></AnimationWrapper>
    </div>
    </div>





      <div className='layout'>

        <div className='container'>
          <div className='row  gy-5'>
            <div className='col-md-4 col-lg-4 col-sm-12'>

              <div class="card pmd-card text-center card-layout h-100">
                <div class="card-body">
                  <div class="pmd-card-icon">
                    {ICN_CH1}
                  </div>
                  <AnimationWrapper animationType="heading">
                  <h4 class="new-card-title pt-5">Virtual Instructor Led Training</h4></AnimationWrapper>
                  <AnimationWrapper animationType="subHeading"> <p class="new-card-text pt-3">Turn tedious online training into interactive, hands-on experiences that learners love.</p></AnimationWrapper>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12'>

              <div class="card pmd-card text-center card-layout h-100">
                <div class="card-body">
                  <div class="pmd-card-icon">
                    {ICN_CH2}
                  </div>
                  <AnimationWrapper animationType="heading">
                  <h4 class="new-card-title pt-5">Self-Paced Learning</h4></AnimationWrapper>
                  <AnimationWrapper animationType="subHeading"><p class="new-card-text pt-3">
                    Quickly create hands-on, self-paced courses that virtually run themselves.</p></AnimationWrapper>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-lg-4 col-sm-12'>

              <div class="card pmd-card text-center card-layout h-100">
                <div class="card-body">
                  <div class="pmd-card-icon">
                    {ICN_CH3}
                  </div>
                  <AnimationWrapper animationType="heading">
                  <h4 class="new-card-title pt-5">Onboarding</h4></AnimationWrapper>
                  <AnimationWrapper animationType="subHeading"><p class="new-card-text pt-3 ">Increase product engagement and adoption with hands-on, interactive learning experiences.</p></AnimationWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='layout'>
        <div className='container'>
          <marquee className="logos">
            <div className='d-flex'><h1 className='marqueemain'>User-friendly,   </h1>
              <h1 className='marqueetext'>Scalable ,</h1>
              <h1 className='marqueemain'>Cost-effective,</h1>
              <h1 className='marqueetext'>Enjoyable…</h1></div>
          </marquee>

        </div>

      </div>

      <div className='layout'>
        <div className='container'>
        <AnimationWrapper animationType="heading">
          <div className='section-heading'>We’ve helped 10000+ Employees globaly working in fortune 100 companies </div></AnimationWrapper>
          <div className='section-sub-heading mt-5'>
          <AnimationWrapper animationType="subHeading">
            <p >
              Testimonials from some of our amazing customers who are building faster.
            </p>
            </AnimationWrapper>
          </div>

        </div>
      </div>

      {/* fast setup */}

     <div className='layout'>
     <div className='container'>
        <div className='row '>
          <div className='col-md-6 col-lg-6 col-sm-12'>
            {/* <div className='d-flex align-items-center' >
<div className='icn-bc'>{IMG_COMMENTS}</div>
<h5 className='mx-5'></h5>
</div> */}
<AnimationWrapper animationType="heading">
            <h4 className='mt-3'>Hands-on experience</h4></AnimationWrapper>
            <AnimationWrapper animationType="subHeading"> <p className='mt-3'>Replicate real-world training environments, no matter how complex – without the risk of human error or security breaches. Deliver interactive, “play and break” training that sticks.</p></AnimationWrapper>
            <AnimationWrapper animationType="listItem"> <div className='sectionpoint mt-3 mb-3'>
              <ul>
                <li>Turn yawn mode off, and video mode on through in-app video conferencing.</li>
                <li>Take a peek into your students’ shoulders with over-the-shoulder capabilities.</li>
                <li>Create an engaging, live classroom experience with built-in chat and file & video sharing.</li>

              </ul>
            </div></AnimationWrapper>

          </div>
          <div className='col-md-6 col-lg-6 col-sm-12'>
          <VideoComponent source="https://gnosislabs.s3.ap-south-1.amazonaws.com/newgif/t1_480p.mp4"/>
          </div>

        </div>
      </div>
     </div>

      {/* Hands on experience */}

      <div className='layout '>
      <div className='container'>
        <div className='row mobile-flxdirectionnew '>
          <div className='col-md-6 col-lg-6 col-sm-12'>
          <VideoComponent source="https://gnosislabs.s3.ap-south-1.amazonaws.com/newgif/t2_480p.mp4"/>
          </div>
          <div className='col-md-6 col-lg-6 col-sm-12'>
            {/* <div className='d-flex align-items-center' >
<div className='icn-bc'>{ICN_HANDSON}</div>
<h5 className='mx-5'>HANDS-ON EXPERIENCES
</h5>
</div> */}
<AnimationWrapper animationType="heading">
            <h4 className='mt-3'>Control costs easily
            </h4></AnimationWrapper>
            <AnimationWrapper animationType="subHeading"><p className='mt-3'>We make it simple to monitor your resources – and you only pay for what you use. Control user permissions, and set up automation to suspend or delete environments when not in use with policies.</p></AnimationWrapper>
            <AnimationWrapper animationType="listItem"><div className='sectionpoint mt-3 mb-3'>
              <ul>
                <li>Track usage through quota dashboards and notifications to manage resources.</li>
                <li>Machine learning algorithms create standby environments ready for use at a flip of a switch.</li>


              </ul>
            </div></AnimationWrapper>

          </div>


        </div>
      </div>
        </div>

      {/* cost control */}
    <div className='layout'>
    <div className='container'>
        <div className='row ' >
          <div className='col-md-6 col-lg-6 col-sm-12'>
            {/* <div className='d-flex align-items-center' >
<div className='icn-bc'>{ICN_COST_CONTROL}</div>
<h5 className='mx-5'>COST CONTROL</h5>
</div> */}
<AnimationWrapper animationType="heading">
            <h4 className='mt-3'>Set up environments in minutes (not days)</h4></AnimationWrapper>
            <AnimationWrapper animationType="subHeading"> <p className='mt-5'>Quickly create or clone exact replicas of your product to showcase its value – without worrying about server space or extra costs.</p></AnimationWrapper>


          </div>
          <div className='col-md-6 col-lg-6 col-sm-12' >
          <VideoComponent source="https://gnosislabs.s3.ap-south-1.amazonaws.com/newgif/t3_480p.mp4"/>
          </div>

        </div>
      </div>
    </div>

      {/* analytics and reporting */}

      <div className='layout '>
      <div className='container'>
        <div className='row mobile-flxdirectionnew'>
          <div className='col-md-6 col-lg-6 col-sm-12' >
          <VideoComponent source="https://gnosislabs.s3.ap-south-1.amazonaws.com/newgif/h4_480p.mp4"/>
          </div>
          <div className='col-md-6 col-lg-6 col-sm-12'>
            {/* <div className='d-flex align-items-center' >
<div className='icn-bc'>{ICN_TRAINING_ANALYTICS}</div>
<h5 className='mx-5'>ANALYTICS & REPORTING
</h5>
</div> */}
<AnimationWrapper animationType="heading">
            <h4 className='mt-3'>Monitor Progress & Prove ROI in real time!</h4></AnimationWrapper>
            <AnimationWrapper animationType="subHeading"><p className='mt-3'>
              Built-in analytics give you a clear overview of who’s doing what in your virtual classes, so you can measure progress and performance, give feedback, and know when to follow up.
            </p></AnimationWrapper>
            <AnimationWrapper animationType="listItem">
            <div className='sectionpoint mt-3 mb-3'>
              <ul>
                <li>Live in-class analytics for immediate insights on the progress of your class.</li>
                <li>Create notifications to track the metrics that are critical to your business.</li>
                <li>Prove ROI with customized analytics reports delivered straight to your inbox.</li>

              </ul>
            </div>
            </AnimationWrapper>
            

          </div>


        </div>
      </div>

      </div>

      {/* ready to explore */}

     <div className='layout'>
     <div className='container mt-5 mb-5'>
        <div className='row readytoexplore' >
          <div className='col-md-7 leftside ' style={{ padding: "90px 50px" }}>
          <AnimationWrapper animationType="heading"><div className='exploretext'>
              Ready to Explore?
            </div></AnimationWrapper>
            <AnimationWrapper animationType="subHeading">
            <div className='exploretextsub'>
              Request a Demo or Start Your Journey Now!
            </div></AnimationWrapper>
            <AnimationWrapper animationType="button">
            <div className='mt-5'>
             <Link  to="/contactus"class=" btn_getintouch">Get in Touch</Link>
             </div></AnimationWrapper>
          </div>
          <div className=' col-md-5' style={{ padding: " 0px" }} >
            <img src={Readytoexplore} className='img-fluid get-image'  />
          </div>

        </div>
      </div>

     </div>


    </>
  )
}

export default ExploreCatalog
import React, { useState } from 'react';
import RestService from '../../Services/api.service';
import './Comments.css';
import AllModal from './AllModal';
import SignupForCart from '../../Screens/Auth/SignupForCart';

function ReplyForm({ parentCommentId, blogSlug, onReplySubmit }) {
  const [replyText, setReplyText] = useState('');
  const [showSignUp, setShowSignUp] = useState(false);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);

  const handleChangeReplyText = (e) => {
    if ('user' in localStorage) {
      if (!hasStartedTyping) setHasStartedTyping(true);
      setReplyText(e.target.value);
    }
    else {
      setShowSignUp(true);
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let consumerSid1 = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : '';

      const payload = {
        text: replyText,
        consumerSid: consumerSid1.sid
      }
      const response = await RestService.replyOnComment(blogSlug, parentCommentId, payload);
      if (response.status === 200) {

        onReplySubmit(response.data.newReply, response.data.commentDetails.commentId);
        setReplyText('');
        // window.location.reload(true);
      }
    } catch (err) {
      console.error("Error submitting reply: ", err);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="reply-form maincomentdiv">
        <div className='row'>
          <div className='col-md-9'>
            <textarea
              className='comment-textarea'
              value={replyText}
              onChange={handleChangeReplyText}
              placeholder="Write your reply here..."
              required
            />
            {hasStartedTyping &&
              <p className="error-message">
                {`${Math.max(0, 500 - replyText.length)} characters left`}
              </p>
            }
          </div>
          <div className='col-md-3'>
            <button
              type="submit"
              style={{
                opacity: (replyText.length > 499 || replyText.length === 0) ? 0.5 : 1,
                cursor: (replyText.length >= 500 || replyText.length === 0) ? 'not-allowed' : 'pointer'
              }}
              disabled={replyText.length >= 500 || replyText.length === 0}
              className="btn-send"
            >
              Post Reply
            </button>

          </div>
        </div>

      </form>
      {
        showSignUp &&
        <AllModal show={showSignUp} setShow={setShowSignUp} headerTitle="" >
          <>
            <SignupForCart setShowSignUp={setShowSignUp} />
          </>
        </AllModal>

      }
    </>

  );
}

export default ReplyForm;

import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
// import RestService from '../../Services/api.service'
import Readytoexplore from "../../../Assets/Images/readytoexplore.png"


import { ToastContainer } from 'react-toastify'
import SkeletonLoader from '../SkeletonLoader'
import ToastMessage from '../../../Store/ToastHook'
import RestService from '../../../Services/api.service'
import { UPLOAD_ICON } from '../Icon'
import { Helmet } from 'react-helmet-async';
import { Spinner } from 'react-bootstrap';
const BecomeTrainer = () => {
  const [loader, setLoader] = useState(true)
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [file, setFile] = useState(null);
  const [yoe, setYoe] = useState('');
  const [privacy, setPrivacy] = useState(false);

  const [firstNameError, setFirstNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [yoeError, setYoeError] = useState('');
  const [fileTypeError, setFileTypeError] = useState('');
  const [imagePreview, setImagePreview] = useState(null);

  const [apiCallSuccess, setApiCallSuccess] = useState(false);


  const inputRef = useRef(null);

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);

    // Validate first name (only letters, no spaces, at least 3 characters)
    const isValid = /^[A-Za-z]+$/.test(value);
    setFirstNameError(
      isValid ? '' : 'Only alphabetic characters'
    );
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Validate email
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(value);
    setEmailError(isValidEmail ? '' : 'Invalid email address');
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);


    // Check if the value is exactly 10 digits long and consists only of numbers
    const isValidLength = value.length === 10;
    const containsOnlyNumbers = /^\d+$/.test(value);

    // Update the error message based on the validation
    if (!containsOnlyNumbers) {
      setPhoneNumberError('Phone number must contain only digits');
    } else if (!isValidLength) {
      setPhoneNumberError('Phone number must be exactly 10 digits');
    } else {
      setPhoneNumberError(''); // No error
    }
  };

  function handleYoeChange(e) {
    const value = e.target.value;
    setYoe(value);

    // Allow only numbers or an empty string (for backspace support)
    const isValidYoe = /^[0-9\b]+$/.test(value);
    setYoeError(
      isValidYoe ? '' : 'Enter numbers only'
    )

  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // setFile(file);
    if (file) {
      const allowedExtensions = ['zip'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        setFile(file);
        setFileTypeError('');
      } else {
        setFile(null);
        setFileTypeError('Invalid file type. Please upload a zip file.');
      }
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };


  const becomeATrainer = () => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append('firstName', firstName);
      formData.append('email', email);
      formData.append('phoneNumber', phoneNumber);
      formData.append('file', file);
      formData.append('YOE', yoe)

      if (lastName.length !== 0) {
        formData.append('lastName', lastName)
      }

      RestService.becomeATrainer(formData).then(res => {

        ToastMessage({ type: "success", message: res.data });

        setEmail('');
        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setYoe('');
        setFile(null);
        setPrivacy(false);
        setApiCallSuccess(true);
        setLoading(false);
        // setShow(false)
      }, err => {
        ToastMessage({ type: "error", message: 'Try Again' });
        setLoading(false);
      }
      );
    }
    catch (err) {
      setLoading(false);
      console.error()
      // Toast.error({ message: Something wrong!! });
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  }, []);

  useEffect(() => {

    const inputElement = inputRef.current;

    const dropZoneElement = inputElement?.closest(".drop-zone");

    const updateThumbnail = (file) => {
      let thumbnailElement = dropZoneElement?.querySelector(".drop-zone__thumb");

      if (dropZoneElement.querySelector(".drop-zone__prompt")) {
        dropZoneElement.querySelector(".drop-zone__prompt").remove();
      }

      if (!thumbnailElement) {
        thumbnailElement = document.createElement("div");
        thumbnailElement.classList.add("drop-zone__thumb");
        dropZoneElement.appendChild(thumbnailElement);
      }

      thumbnailElement.dataset.label = file.name;

      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // reader.onload = () => {
        //   thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
        // };
      }
      //    else {
      //     thumbnailElement.style.backgroundImage = null;
      //   }
    };

    const handleClick = () => {
      inputElement.click();
    };

    const handleChange = (e) => {
      if (inputElement.files.length) {
        updateThumbnail(inputElement.files[0]);
      }
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      dropZoneElement.classList.add("drop-zone--over");
    };

    const handleDragLeave = () => {
      dropZoneElement.classList.remove("drop-zone--over");
    };

    const handleDrop = (e) => {
      e.preventDefault();

      if (e.dataTransfer.files.length) {
        const file = e.dataTransfer.files[0];
        const allowedExtensions = ['zip'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          setFile(file);
          setFileTypeError('');
        } else {
          setFile(null);
          setFileTypeError('Invalid file type. Please upload a zip file.');
        }
        // setFile(file);
        updateThumbnail(file);

        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }

      dropZoneElement.classList.remove("drop-zone--over");
    };


    // Event listeners
    dropZoneElement?.addEventListener("click", handleClick);
    inputElement?.addEventListener("change", handleChange);
    dropZoneElement?.addEventListener("dragover", handleDragOver);
    ["dragleave", "dragend"].forEach((type) => {
      dropZoneElement?.addEventListener(type, handleDragLeave);
    });
    dropZoneElement?.addEventListener("drop", handleDrop);

    // Cleanup event listeners on component unmount
    return () => {
      dropZoneElement?.removeEventListener("click", handleClick);
      inputElement?.removeEventListener("change", handleChange);
      dropZoneElement?.removeEventListener("dragover", handleDragOver);
      ["dragleave", "dragend"].forEach((type) => {
        dropZoneElement?.removeEventListener(type, handleDragLeave);
      });
      dropZoneElement?.removeEventListener("drop", handleDrop);
    };



  }, []);

  useEffect(() => {
    if (apiCallSuccess) {
      const dropZoneElement = inputRef.current?.closest(".drop-zone");
      const thumbnailElement = dropZoneElement?.querySelector(".drop-zone__thumb");
      if (thumbnailElement) {
        thumbnailElement.remove();
      }

      // Check if .drop-zone__prompt exists, if not, create and append it
      if (!dropZoneElement.querySelector(".drop-zone__prompt")) {
        const promptElement = document.createElement("div");
        promptElement.classList.add("drop-zone__prompt");
        promptElement.innerHTML = `
          <span class="click">Click to upload</span> or Drop file here
        `;
        dropZoneElement.appendChild(promptElement);
      }
    }
    setApiCallSuccess(false);
  }, [apiCallSuccess]);





  return (
    <>
    <Helmet>
    <title>Become a Trainer with Learnlytica - Share Your Expertise</title>
    <meta name="description" content="Become a trainer with Learnlytica and share your expertise with learners worldwide. Join our team of skilled educators, instructional designers, and subject matter experts in creating impactful learning experiences."/>
    <meta name="keywords" content="Become a trainer, Learnlytica trainer, educational expertise, subject matter experts, instructional designers, online education"/>
    
    </Helmet>
      <ToastContainer />
      <div className='container'>
        <div className='row'>

          <div className='col-md-6 col-lg-6 col-xl-6 col-sm-12 mt-5'>
            {loader ?
              <SkeletonLoader skeletonCount={5} />
              :
              <h2 className='contactusheader mt-5'>

                Become a Trainer</h2>
            }
            {loader ?
              <SkeletonLoader skeletonCount={5} />
              :
              <p className='pt-3 contactussubheader'>Elevate Your Career with Rewarding Earnings and Continuous Learning
                :</p>
            }

            {loader ?
              <SkeletonLoader skeletonCount={5} />
              :
              <div className='sectionpoint pt-5 mb-3'>
                <ul>
                  <li> Competitive Compensation: Benefit from our attractive pay structure that rewards your expertise and dedication.
                  </li>
                  <li> Additional Incentives: Enjoy performance-based bonuses and other financial incentives that recognize your hard work and success.
                  </li>
                  <li> Flexible Income Opportunities: Choose from various programs and schedules to maximize your earning potential.
                  </li>

                </ul>
              </div>
            }
          </div>


          <div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
            {/* {
              loader ?
                <SkeletonLoader skeletonCount={5.6} />
                : */}
            <div class="signup-form">

              {/* <div className='text-center'>
    {ICN_LOGO}
</div> */}

              {/* <hr/> */}

              <div className='row'>
                <div className='col-md-6 col-lg-6 col-sm-12'>
                  <div class="form-group ">
                    <label>First Name<sup style={{ fontSize: "14px" }}>*</sup></label>
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
    <span class="input-group-text">
      <span class="fa fa-user"></span>
    </span>                    
  </div> */}
                      <input type="text" onChange={handleFirstNameChange} value={firstName} class="form-control" name="firstName" placeholder="First Name" />
                    </div>
                    {firstNameError && <div className="error-message text-danger">{firstNameError}</div>}
                  </div>
                </div>

                <div className='col-md-6 col-lg-6 col-sm-12'>
                  <div class="form-group ">
                    <label>Last Name</label>
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
    <span class="input-group-text">
      <span class="fa fa-user"></span>
    </span>                    
  </div> */}
                      <input type="text" onChange={(e) => setLastName(e.target.value)} value={lastName} class="form-control" name="lastName" placeholder="Last Name" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label>Email<sup style={{ fontSize: "14px" }}>*</sup></label>
                <div class="input-group">
                  {/* <div class="input-group-prepend">
    <span class="input-group-text">
      <i class="fa fa-paper-plane"></i>
    </span>                    
  </div> */}
                  <input type="email" onChange={handleEmailChange} class="form-control" name="email" placeholder="Email Address" value={email} />

                </div>
                {emailError && <div className="error-message text-danger">{emailError}</div>}
              </div>
              <div class="form-group">
                <label>Phone Number<sup style={{ fontSize: "14px" }}>*</sup></label>
                <div class="input-group">
                  {/* <div class="input-group-prepend">
    <span class="input-group-text">
      <i class="fa fa-lock"></i>
    </span>                    
  </div> */}

                  <input type="text" onChange={handlePhoneNumberChange} value={phoneNumber} class="form-control" name="phoneNumber" placeholder="Enter your Number" />

                </div>
                {phoneNumberError && <div className="error-message text-danger">{phoneNumberError}</div>}
              </div>
              <div class="form-group">
                <label>Total Year of Training Exp <sup style={{ fontSize: "14px" }}>*</sup></label>
                <div class="input-group">
                  {/* <div class="input-group-prepend">
    <span class="input-group-text">
      <i class="fa fa-lock"></i>
    </span>                    
  </div> */}

                  <input type="text" onChange={handleYoeChange} value={yoe} class="form-control" name="yoe" placeholder=" YOE" />

                </div>
                {yoeError && <div className="error-message text-danger">{yoeError}</div>}

              </div>

              <div className='form-group'>
                <label>
                  Upload your sample training video ( Only Zip ) <sup style={{ fontSize: "14px" }}>*</sup>

                </label>
                <div className='row' >



                  <div className="drop-zone">
                    <div className='d-flex' style={{ flexDirection: "column", cursor: "pointer" }}>
                      <div className='text-center dropzone_icon'>{UPLOAD_ICON}</div>
                      <span className="drop-zone__prompt">
                        <span className='click'>Click to upload</span> or Drop file here
                      </span>
                    </div>
                    <input type="file" name="myFile" className="drop-zone__input" ref={inputRef} accept='.zip' onChange={handleFileChange} />

                  </div>
                  {/* <input type="file" name="myFile" className="drop-zone__input" accept='.zip' ref={inputRef} onChange={handleFileChange} /> */}

                  {/* <div className='row mt-2 b-top'>
                   {
                     imagePreview !== null &&

                     <div className='ml-auto'> 
                       <button className='btn-save mx-2' style={{ float: "inline-end" }} onClick={() => uploadProfilePicture()}>Upload</button>

                     </div>
                   }
                 </div> */}
                </div>
                {fileTypeError && <div className="error-message text-danger">{fileTypeError}</div>}

              </div>


              <div class="form-group">
                <label class="form-check-label"><input type="checkbox" checked={privacy} onClick={(e) => setPrivacy(e.target.checked)} />

                  <span className='mx-2'> You agree to our friendly</span>  <Link to="/legal/privacy">privacy policy.</Link>


                </label>
              </div>





              <div class="form-group">
                <button type="submit"
                  disabled={
                    !privacy ||
                    firstName.length === 0 || email.length === 0
                    || phoneNumber.length < 10 ||
                    yoe.length === 0 ||
                    file === null || fileTypeError.length > 0 ||
                    firstNameError.length > 0 || emailError.length > 0
                    || phoneNumberError.length > 0 ||
                    yoeError.length > 0}
                  onClick={() => becomeATrainer()}
                  class="btn btn-primary btn-lg"
                >
               { loading ? (
                  <>
                      <Spinner
                          as="span"
                          animation="border"
                          size="md"
                          role="status"
                          aria-hidden="true"
                          className="mr-2" // Add margin to separate spinner from text
                          style={{ border: '5px dotted #fff' }}
                      />
                      {' '}
                      Loading...
                  </>
              ) :


                "Submit"}</button>
              </div>








            </div>
            {/* } */}
          </div>

        </div>
      </div>

    </>
  )
}

export default BecomeTrainer
import React,{useEffect,useState} from 'react'
import Legal from './Legal'
import SkeletonLoader from '../../Components/Common/SkeletonLoader';

const TermsAndConditions = () => {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  }, []);
  return (
    <>
    <div className='course-container'>
<div className='row '>
    {/*  filter options */}
<div className='col-lg-3 col-md-3 col-sm-12  mb-5' >
    <Legal/>
    </div>

    <div className='col-lg-9 col-md-9 col-sm-12  mb-5'>
    <div className='course-section'>
    {loader ?
                  <SkeletonLoader skeletonCount={15} />:
        <div className='row container'>
            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
            <div className='learningpt-header' >Terms & Conditions</div>
            <div className='requirment pt-5'>Last updated on Jan 2nd 2024</div>
            

            <div className='requirment pt-5'>
            For the purpose of these Terms and Conditions, the terms "we," "us," and "our" used anywhere on this page shall mean LEARNLYTICA PRIVATE LIMITED, whose registered/operational office is 17/1, 2nd Floor, Ambalipura Sarjapura Road, Bellandur Bengaluru KARNATAKA 560103. The terms "you," "your," "user," and "visitor" shall mean any natural or legal person who is visiting our website and/or has agreed to purchase courses from us.  
            </div>

<div className='requirment pt-5'>Your use of the website and/or purchase of courses from us are governed by the following Terms and Conditions:</div>
<h5 className='requirment pt-5'>Course Information and Changes:</h5>    
<div className='requirment'>
              <ul className='pt-2'>
                <li >The content of the pages of this website, including course details, is subject to change without notice. We strive to provide accurate and timely information but acknowledge that updates may occur.</li>
                


              </ul>
            
            
            </div>

            <h5 className='requirment pt-5'> Accuracy and Suitability:</h5>    
            <div className='requirment'>
                          <ul className='pt-2'>
                           
                           <li>
                            Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials related to our courses. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
                            </li>
                          </ul>
                        
                        
                        </div>

                        <h5 className='requirment pt-5'> Use at Your Own Risk:</h5>    
                        <div className='requirment'>
                                      <ul className='pt-2'>
                                       
                                       <li>
                                      
                                       Your use of any information or materials on our website and/or course pages is entirely at your own risk. It is your responsibility to ensure that the courses meet your specific requirements.
                                        </li>
                                      </ul>
                                    
                                    
                                    </div>

                                    <h5 className='requirment pt-5'>Intellectual Property:</h5>    
                                    <div className='requirment'>
                                                  <ul className='pt-2'>
                                                   
                                                   <li>
                                                  
                                                  
Our website and course materials contain materials owned by or licensed to us, including design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
                                                   </li>
                                                  </ul>
                                                
                                                
                                                </div>
                                                <h5 className='requirment pt-5'>Trademarks:</h5>    
                                                <div className='requirment'>
                                                              <ul className='pt-2'>
                                                               
                                                               <li>
                                                            
                                                               All trademarks reproduced on our website that are not the property of, or licensed to, the operator are acknowledged on the website.                                             
            </li>
                                                              </ul>
                                                            
                                                            
                                                            </div>
                                                            <h5 className='requirment pt-5'>
                                                            Unauthorized Use:</h5>    
                                                            <div className='requirment'>
                                                                          <ul className='pt-2'>
                                                                           
                                                                           <li>
                                                                        
                                                                           Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.
                                                                           </li>
                                                                          </ul>
                                                                        
                                                                        
                                                                        </div>

                                                                        <h5 className='requirment pt-5'>
                                                                        External Links:</h5>    
                                                            <div className='requirment'>
                                                                          <ul className='pt-2'>
                                                                           
                                                                           <li>
                                                                           
                                                                           From time to time, our website may include links to other websites for additional information. These links are provided for your convenience, and we do not endorse the content of external sites.
                                                                           </li>
                                                                          </ul>
                                                                        
                                                                        
                                                                        </div>
                                                                        <h5 className='requirment pt-5'>
                                                                        Linking to Our Website:</h5>    
                                                            <div className='requirment'>
                                                                          <ul className='pt-2'>
                                                                           
                                                                           <li>
                                                                           
                                                                           You may not create a link to our website from another website or document without LEARNLYTICA PRIVATE LIMITED’s prior written consent.
                                                                           </li>
                                                                          </ul>
                                                                        
                                                                        
                                                                        </div>

                                                                        <h5 className='requirment pt-5'>
                                                                        Governing Law:</h5>    
                                                            <div className='requirment'>
                                                                          <ul className='pt-2'>
                                                                           
                                                                           <li>
                                                                           
                                                                          
                                                                           Any dispute arising out of the use of our website, purchase of courses, or any engagement with us is subject to the laws of India.
                                                                           </li>
                                                                          </ul>
                                                                        
                                                                        
                                                                        </div>

                                                                        <h5 className='requirment pt-5'>
                                                                        Transaction Decline:</h5>    
                                                            <div className='requirment'>
                                                                          <ul className='pt-2'>
                                                                           
                                                                           <li>
                                                                      
                                                                           We shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any transaction, on account of the cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.
                                                                           </li>
                                                                          </ul>
                                                                        
                                                                        
                                                                        </div>


<div className='requirment pt-5 pb-5'>By using our website and purchasing courses, you agree to abide by these Terms and Conditions.</div>


            </div>

        </div>
}

    </div>



    
    
    
    </div>

    </div>
</div>
    
    </>
  )
}

export default TermsAndConditions
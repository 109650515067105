import React, { useContext, useEffect, useState } from 'react';
import { ICN_CHROME, ICN_HEADER_LOGO1, ICN_LOGO } from '../../Components/Common/Icon';
import "./Auth.css";
import { Link } from 'react-router-dom';
import RestService from '../../Services/api.service';
import AxiosService from '../../Services/axios.service';
import { TokenService } from '../../Services/storage.service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ToastMessage from '../../Store/ToastHook';
import { ToastContainer } from 'react-toastify';
import CryptoJS from 'crypto-js';
import hidePwdImg from "./hide-password.svg";
import showPwdImg from "./show-password.svg";
import { Navigate } from 'react-router-dom';
import CartContext from '../../Store/CartContext';
import { FcGoogle } from 'react-icons/fc';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { Pause } from '@mui/icons-material';
import { Spinner } from 'react-bootstrap';

const Login = ({ isLoggedIn }) => {
	const [loading, setLoading] = useState(false);
	const [isRevealPwd, setIsRevealPwd] = useState(false);
	const [agree, setAgree] = useState(false);
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [password, setPassword] = useState('');
	const [isValid, setIsValid] = useState(null);
	const { setConsumerDetails, setCartResponse,
		setCouponCode,
		setDiscount,
		setOriginalCartPrice } = useContext(CartContext);
	const navigate = useNavigate();
	const secretKey = process.env.REACT_APP_SECRET;

	const validateEmail = (input) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
		setIsValid(emailRegex.test(input));
	};

	const handleChange = (e) => {
		const inputValue = e.target.value;
		setEmail(inputValue);

		// Show validation message only when the user starts typing
		if (inputValue.length > 0) {
			validateEmail(inputValue);
		} else {
			setIsValid(null); // Reset validation when the input is empty
		}
	};

	//display cart
	const displayCart = () => {
		try {
			// spinner.show();
			RestService.displayCart().then(
				response => {
					if (response.status === 200) {
						setCartResponse(response.data);
						setCouponCode(response.data.couponCode);
						setDiscount(response.data.discount);
						setOriginalCartPrice(response.data.originalCartPrice);


					}
				},
				err => {
					console.error(err);
				}
			)
		} catch (err) {
			console.error("error occur on displayCart()", err)
		}
	}





	const onGoogleLogin = (name, google_email) => {

		const payload = {
			'email': google_email,
			"name": name,
		}
		try {
			RestService.Googlelogin(payload).then(
				response => {
					if (response.status === 200) {

						let data = response.data
						setConsumerDetails(response.data?.consumerProfileDetails);
						localStorage.setItem('user', JSON.stringify(data));
						localStorage.setItem("timestamp", moment().format());
						AxiosService.init('', response.data?.jwtToken);
						TokenService.saveToken(response.data?.jwtToken);
						setEmail('');
						setPassword('');
						// setTimeout(() => {
						navigate('/course-library');
						displayCart();
						// }, 2000);
					}


				},
				err => {

					if (err.request.status === 0) {

						ToastMessage({ type: "error", message: 'Please Check Your Network Connection', time: 2500 });

					}
					else if (err.response.status === 401) {

						ToastMessage({ type: "error", message: 'Invalid User Name / Password!', time: 2500 });

					}
					else if (err.response.status === 500) {

						ToastMessage({ type: "error", message: `${err.response.data.message}`, time: 2500 });

					}
					else {

						ToastMessage({ type: "error", message: 'User with email already exist', time: 2500 });
					}

					// spinner.hide();
				}
			).finally(() => {
				// spinner.hide();
			});
		} catch (err) {
			// Toast.error({ message: 'Invalid User Name / Password!' })
			console.error("Error occured on login page", err)
		}
	}
	// on login the user
	const onLogin = () => {
		setLoading(true); // Set loading state to true when login process starts
	
		const type = 'CONSUMER';
		const passwordEncrypt = CryptoJS.AES.encrypt(password, secretKey).toString();
	
		const payload = {
			'email': email,
			'password': passwordEncrypt,
		};
	
		try {
			RestService.login(payload, type).then(
				response => {
					if (response.status === 200) {
						let data = response.data;
						setConsumerDetails(response.data?.consumerProfileDetails);
						localStorage.setItem('user', JSON.stringify(data));
						localStorage.setItem("timestamp", moment().format());
						AxiosService.init('', response.data?.jwtToken);
						TokenService.saveToken(response.data?.jwtToken);
						setEmail('');
						setPassword('');
						navigate('/course-library');
						displayCart();
					}
				},
				err => {
					if (err.request.status === 0) {
						ToastMessage({ type: "error", message: 'Please Check Your Network Connection', time: 2500 });
					}
					else if (err.response.status === 401) {
						ToastMessage({ type: "error", message: 'Invalid User Name / Password!', time: 2500 });
					}
					else if (err.response.status === 500) {
						ToastMessage({ type: "error", message: `${err.response.data.message}`, time: 2500 });
					}
					else {
						ToastMessage({ type: "error", message: 'User with email already exist', time: 2500 });
					}
				}
			).finally(() => {
				setLoading(false); // Set loading state to false when login process finishes
			});
		} catch (err) {
			console.error("Error occurred on login page", err);
			setLoading(false); // Ensure loading state is set to false even if an error occurs
		}
	}
	// useEffect(()=> {
	// 	console.log('Hii')
	// 	insertScript();
	//  }, [])
	// const insertScript = ()=>{
	//   const script = document.createElement('script');
	//   script.src = 'https://apis.google.com/js/platform.js';
	//   script.onload = ()=> {
	// 	 initializeGoogleSigIn();
	//   }
	//   document.body.appendChild(script);
	// }

	// const initializeGoogleSigIn = ()=>  {
	// 	window.gapi.load('auth2', () => {
	// 	  let auth2 = gapi.auth2.init({
	// 		client_id: '875558919636-lu0blapichklfhc7gjgfko4ff94et0m1.apps.googleusercontent.com',
	// 	  })
	// 	  console.log('api inited')
	// 	  window.gapi.load('signin2', function () {
	// 		// render a sign in button
	// 		// using this method will show Signed In if the user is already signed in
	// 		var params = {
	// 			'width': 240,
	// 			'height': 50,
	// 			'longtitle': true,
	// 			onsuccess: (googleUser) => {
	// 				console.log('user has finished sigin');
	// 				var profile = googleUser.getBasicProfile();
	// 				var id_token = googleUser.getAuthResponse().id_token;
	// 				console.log('Name: ' + profile.getName());
	// 				console.log('Image: ' + profile.getImageUrl());
	// 				console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
	// 				console.log('Email: ' + profile.getEmail());
	// 			  }
	// 		}
	// 		window.gapi.signin2.render('loginButton', params)
	// 	  })
	// 	})
	//   }

	const responseGoogle = async (response) => {
		var userObject = jwtDecode(response.credential);

		// localStorage.setItem('user', JSON.stringify(userObject));

		const { name, sub, picture } = userObject;
		const google_email = userObject.email

		const doc = {
			_id: sub,
			_type: 'user',
			userName: name,
			image: picture,
		};
		setName(name);
		setEmail(userObject.email)
		await onGoogleLogin(name, google_email)
		// navigate('/course-library', { replace: true });
		// displayCart();

	}

	//if login and user tries to login again, redirect to my enrolled courses
	if (isLoggedIn) {
		return <Navigate to="/mycourses" replace />;
	}


	return (
		<>
			<ToastContainer />
			<div className=" my-2">
				<div class="signup-form">
					{/* <form action="/examples/actions/confirmation.php" method="post"> */}
					<div className='text-center d-none d-lg-block '>
						{ICN_LOGO}
					</div>
					<h2 className='pt-4'>Log in to your account</h2>
					<p className='pt-3'>Welcome back! Please enter your details.</p>
					{/* <hr/> */}

					<div className='container'>
						<div class="form-group">
							<label>Email<sup style={{ fontSize: "13px" }}>*</sup></label>
							<div class="input-group">
								{/* <div class="input-group-prepend">
					<span class="input-group-text">
						<i class="fa fa-paper-plane"></i>
					</span>                    
				</div> */}
								<input type="email" class="form-control" name="email" placeholder="Email Address" onChange={handleChange} />
							</div>
							{isValid === false && (
								<p style={{ color: 'red', marginRight: "250px" }}>Email is not valid</p>
							)}
						</div>
						<div class="form-group">
							<label>Password<sup style={{ fontSize: "13px" }}>*</sup></label>
							<div class="input-group">
								{/* <div class="input-group-prepend">
					<span class="input-group-text">
						<i class="fa fa-lock"></i>
					</span>                    
				</div> */}
								<div className='pwd-container'>
									<input type={isRevealPwd ? "text" : "password"} class="form-control" name="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
									<img
										title={isRevealPwd ? "Hide password" : "Show password"}
										src={isRevealPwd ? hidePwdImg : showPwdImg}
										onClick={() => setIsRevealPwd(prevState => !prevState)}
									/>
								</div>

							</div>
						</div>
						{/* <div class="form-group">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">
						<i class="fa fa-lock"></i>
						<i class="fa fa-check"></i>
					</span>                    
				</div>
				<input type="text" class="form-control" name="confirm_password" placeholder="Confirm Password" required="required"/>
			</div>
        </div> */}
						{/* <div class="form-group">
			<label class="form-check-label"><input type="checkbox" required="required"/>
            
             I accept the <a href="#">Terms of Use</a> &amp; <a href="#">Privacy Policy</a>
            
            
            </label>
		</div> */}
						<div class="form-group" style={{ justifyContent: "space-between" }}>
							<label class="form-check-label"><input type="checkbox" /> Remember me</label>
							<Link to="/forgotpassword" style={{ float: "right" }}>Forgot Password</Link>
						</div>
						<div class="form-group">
	
						<button
						type="submit"
						className="btn btn-primary btn-lg"
						onClick={onLogin}
						disabled={!isValid || password.length === 0 || loading} // Disable button when loading
					>
					{loading ? (
						<>
							<Spinner
								as="span"
								animation="border"
								size="md"
								role="status"
								aria-hidden="true"
								className="mr-2" // Add margin to separate spinner from text
								style={{border: '5px dotted #fff'}}
							/>
							{' '}
							Loading...
						</>
					) : 'Sign in'}{/* Show loading text or original button text */}
					</button>
							</div>


						<h3>OR</h3>
						<div class="form-group">
							<GoogleOAuthProvider
								clientId="875558919636-lu0blapichklfhc7gjgfko4ff94et0m1.apps.googleusercontent.com"
							>
								<GoogleLogin
									render={(renderProps) => (
										<button
											type="button"
											className="bg-mainColor flex justify-center items-center p-3 rounded-lg cursor-pointer outline-none"
											onClick={renderProps.onClick}
											disabled={renderProps.disabled}
										>
											<FcGoogle className="mr-4" /> Sign in with google
										</button>
									)}
									onSuccess={responseGoogle}
									onFailure={responseGoogle}
									cookiePolicy="single_host_origin"
								/>
							</GoogleOAuthProvider>
							{/* <button id='loginButton' type="submit" class="btn-login  btn-lg">{ICN_CHROME} Sign up with Google</button> */}
						</div>




						{/* </form> */}

						<div class="text-center alredyhv">Don’t have an account? <Link to="/signup">Sign up</Link></div>


					</div>


				</div>
			</div>






		</>
	)
}

export default Login
import React, { useState, useEffect } from 'react';
import { ICN_COST_CONTROL, ICN_HANDSON, IMG_COMMENTS, IMG_COST_CONTROL, IMG_EXPLORE_HEADER, IMG_FEATURE, IMG_HANDSON, IMG_SANDBOX_HEADER } from '../../Components/Common/Icon'
import { Link } from 'react-router-dom'
import Readytoexplore from "../../Assets/Images/readytoexplore.png"
import SkeletonLoader from '../../Components/Common/SkeletonLoader'
import AnimationWrapper  from '../../Components/Common/Animation Ref/AnimationWrapper';
import VideoComponent from '../../Components/Common/VideoComponent';
const Sandbox = () => {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  }, []);
  return (
    <>
      <div >
        <div className='container'>
          <div className='row parrent' style={{ alignItems: "center" }} >
            <div className='col-lg-12 col-md-12 col-sm-12 mt-5 mb-5'>
              {
                loader ?
                  <SkeletonLoader skeletonCount={5} />
                  :
                  <AnimationWrapper animationType="heading">
                  <div className='ph' >
                  Empowering Learning Through 
                    <div>Dynamic Labs: Where Skills </div>
                    <div>Take Shape Instantly</div>

                  </div></AnimationWrapper>
              }

              {

                loader ?
                  <SkeletonLoader skeletonCount={2.5} />
                  :
                  <AnimationWrapper animationType="subHeading">
                  <div className='pt mt-5 pb-5'>
                    — We are committed to provide the best possible learning experience for

                    <div>our Learners. Our cutting-edge lab environment is designed to facilitate </div>

                    <div> hands-on learning in various technologies </div>

                  </div></AnimationWrapper>
              }

              {loader ?
                <SkeletonLoader skeletonCount={0.3} />
                :
                <AnimationWrapper animationType="button">
                <div className='mt-3 mobile-btn'>
                  <Link to="/contactus" class="btn-primary mobile-btn" style={{ padding: "10px 18px" }}>Book a Demo</Link>
                </div></AnimationWrapper>
              }
            </div>
            
              <div className='col-lg-12 col-md-12 col-sm-12 mt-3' >
              {loader ?
              <SkeletonLoader skeletonCount={4.5} />
              :
                IMG_SANDBOX_HEADER
              }
              </div>
            
          </div>

        </div>
      </div>

      <div className='container'>
      <AnimationWrapper animationType="heading">
        <div className='subheading mobile-m-top'>Learnlytica for Sandboxing</div></AnimationWrapper>
        <AnimationWrapper animationType="heading"><div className='ph mt-5 ' style={{ fontWeight: "500", textAlign: "center" }}>
        From Concept to Completion: 
          <div>Labs That Adapt to Your Needs </div>
        </div></AnimationWrapper>
        <AnimationWrapper animationType="subHeading">
        <div className='text-center mobile-text mt-3'>
          <div className='text'> Explore our dynamic lab environments, where learning meets innovation.

          </div>
          <div className='text'>Instant setup, personalized experiences – your journey starts here.</div>
          <div className='text'>Join us and redefine the way you learn, one lab at a time.</div>
         
        </div></AnimationWrapper>
      </div>


 


      {/* fast setup */}

      <div className='layout'>
        <div className='container'>
          <div className='row parrent'>
            <div className='col-md-6 col-lg-6 col-sm-12'>
              <div className='d-flex align-items-center' >
                <div className='icn-bc'>{IMG_COMMENTS}</div>
                
        <AnimationWrapper animationType="heading"><h5 className='mx-5'>FAST SETUP</h5></AnimationWrapper>
              </div>
              <AnimationWrapper animationType="heading"><h4 className='mt-3'>Effortless Setup, Instant Access </h4></AnimationWrapper>
              {/* <AnimationWrapper animationType="subHeading"><p className='mt-3'>Spin up and tear down environments in minutes, revert to “golden images”, and make specific changes whenever you need to.</p></AnimationWrapper> */}
              <AnimationWrapper animationType="listItem">
              <div className='sectionpoint mt-3 mb-3'>
                <ul>
                  <li>Effortlessly launch lab environments in seconds with our intuitive interface and streamlined processes. 
                  </li>
                  <li>Adapt to evolving needs seamlessly as our lab environments are agile, allowing for quick adjustments and modifications. 
                  </li>
                  <li>Enjoy uninterrupted learning experiences with self-healing capabilities that ensure continuous operation and reliability. 
                  </li>

                </ul>
              </div></AnimationWrapper>

            </div>
            <div className='col-md-6 col-lg-6 col-sm-12'>
            <VideoComponent source="https://gnosislabs.s3.ap-south-1.amazonaws.com/newgif/s1_480p.mp4"/>
            </div>

          </div>
        </div>
      </div>

      {/* Hands on experience */}

      <div className='container'>
        <div className='row parrent'>
          <div className='col-md-6 col-lg-6 col-sm-12'>
          <VideoComponent source="https://gnosislabs.s3.ap-south-1.amazonaws.com/newgif/s2_480p.mp4"/>
          </div>
          <div className='col-md-6 col-lg-6 col-sm-12'>
            <div className='d-flex align-items-center' >
              <div className='icn-bc'>{ICN_HANDSON}</div>
              <AnimationWrapper animationType="heading"><h5 className='mx-5'>Increase productivity
              </h5></AnimationWrapper>
            </div>
            <AnimationWrapper animationType="heading"><h4 className='mt-3'>Boost Team Productivity with Auto Launch
            </h4></AnimationWrapper>
            {/* <AnimationWrapper animationType="subHeading">
            <p className='mt-3'>Accelerate release cycles by quickly reproducing issues and sharing environments and resources anywhere in the world.</p></AnimationWrapper> */}
            <AnimationWrapper animationType="listItem">
            <div className='sectionpoint mt-3 mb-3'>
              <ul>
                <li>Our auto-launch feature eliminates setup hassles, allowing teams to focus on learning and collaborating instantly.

                </li>
                <li>Seamlessly merge learning and productivity with our one-click setup, empowering teams to transition effortlessly between tasks. 

                </li>
                <li>By removing setup barriers, our platform ensures teams can quickly access learning resources, enhancing overall productivity and skill development.

                </li>

              </ul>
            </div></AnimationWrapper>

          </div>


        </div>
      </div>

      {/* cost control */}
      <div className='container'>
        <div className='row parrent'>
          <div className='col-md-6 col-lg-6 col-sm-12'>
            <div className='d-flex align-items-center' >
              <div className='icn-bc'>{ICN_COST_CONTROL}</div>
              <AnimationWrapper animationType="heading">
              <h5 className='mx-5'>COST CONTROL</h5></AnimationWrapper>
            </div>
            <AnimationWrapper animationType="heading">
            <h4 className='mt-3'>Cost Control Through Streamlined Setup and Agile Environments 
            </h4></AnimationWrapper>
            {/* <AnimationWrapper animationType="subHeading">
            <p className='mt-3'>Set customizations that auto-suspend or delete environments when they sense inactivity.
            </p></AnimationWrapper> */}
            <AnimationWrapper animationType="listItem">
            <div className='sectionpoint mt-3 mb-3'>
              <ul>
                <li>Ensure optimal utilization of resources by dynamically allocating them based on learning needs, minimizing unnecessary expenditure
                </li>
                <li>Easily scale your learning environment as needed without incurring additional costs, thanks to our efficient setup and resource management solutions. 

                </li>
                <li>Optimize resource usage and minimize wastage through automated launch and dynamic lab configurations. </li>
                <li>Save on costs while ensuring effective learning outcomes with our optimized approach to resource allocation and management. </li>


              </ul>
            </div></AnimationWrapper>

          </div>
          <div className='col-md-6 col-lg-6 col-sm-12' >
          <VideoComponent source="https://gnosislabs.s3.ap-south-1.amazonaws.com/newgif/s3_480p.mp4"/>
          </div>

        </div>
      </div>


      {/* ready to explore */}

      <div className='layout'>
        <div className='container '>
          <div className='row readytoexplore' >
            <div className='col-md-7 col-sm-12 leftside ' style={{ padding: "90px 50px" }}>
            <AnimationWrapper animationType="heading"><div className='exploretext'>
                Ready to Explore?
              </div></AnimationWrapper>
              <AnimationWrapper animationType="subHeading">
              <div className='exploretextsub'>
                Request a Demo or Start Your Journey Now!
              </div></AnimationWrapper>
              <AnimationWrapper animationType="button">
              <div className='mt-5'>
             <Link  to="/contactus"class=" btn_getintouch">Get in Touch</Link>
             </div></AnimationWrapper>
            </div>
            <div className=' col-md-5 col-sm-12' style={{ padding: " 0px" }} >
              <img src={Readytoexplore} className='img-fluid' style={{
                float: "inline-end", borderTopRightRadius: "24px",
                borderBottomRightRadius: "24px"
              }} />
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default Sandbox
import React, { useState } from "react";
import {
  ICN_HEADER_EXPAND,
  ICN_LEARNLYTICA_BG_WHITE,
  ICN_LOGO,
  IMG_HEADER,
} from "./Icon";
import { Link } from "react-router-dom";
import RestService from "../../Services/api.service";
import { ToastContainer } from "react-toastify";
import ToastMessage from "../../Store/ToastHook";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleusa, setIsVisibleUsa] = useState(false);

  //email for newsletter
  const [email, setEmail] = useState("");
  const [isValidEmail, setValidEmail] = useState(true);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const isValid = emailRegex.test(enteredEmail);
    setValidEmail(isValid);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setIsVisibleUsa(false);
  };
  const toggleVisibilityUsa = () => {
    setIsVisibleUsa(!isVisibleusa);
    setIsVisible(false);
  };

  //newsletter api
  const newsLetterSubscribe = () => {
    setLoading(true);
    try {
      // spinner.show();

      RestService.newsLetterSubscribe(email).then(
        (response) => {
          if (response.status === 200) {
            ToastMessage({
              type: "success",
              message: "Subscription to newsletter successful",
            });
            setEmail("");
            setLoading(false);
          }
        },
        (err) => {
          console.error(err);
          setLoading(false);
        }
      );
    } catch (err) {
      setLoading(false);
      console.error("error occur on newsLetterSubscribe()", err);
    }
  };

  const linkToLibrary = (event, sid, name) => {
    event.preventDefault();
    localStorage.setItem("sid", sid);
    localStorage.setItem("name", name);
    navigate("/course-library");
  };

  return (
    <>
      <ToastContainer />
      <footer class="footer-section">
        <div class="container">
          <div class="footer-cta pt-5 pb-5">
            <div class="row">
              <div class="col-xl-8 col-md-8 col-sm-12 mb-30">
                <div class="single-cta">
                  <i class="fas fa-map-marker-alt"></i>
                  <div class="cta-text pb-5">
                    <h4>Join our newsletter</h4>
                    <span>
                      We’ll send you a nice letter once per week. No spam.
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-md-4 col-sm-12 mb-30">
                <div class="subscribe-form">
                  {/* <form action="#"> */}
                  <div className="d-flex mobile-flxdirection gap-3">
                    <input
                      type="email"
                      id="email"
                      value={email}
                      placeholder="Email Address"
                      onChange={handleEmailChange}
                      style={{
                        borderColor: isValidEmail ? "initial" : "red",
                        outline: "none",
                      }}
                    />

                    {/* <input type="text" placeholder="Email Address" /> */}
                    <button
                      style={{
                        cursor:
                          (email.length === 0 || isValidEmail === false) &&
                          "not-allowed",
                        opacity:
                          (email.length === 0 || isValidEmail === false) &&
                          "0.7",
                      }}
                      disabled={
                        email.length === 0 || isValidEmail === false || loading
                      }
                      onClick={newsLetterSubscribe}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="md"
                            role="status"
                            aria-hidden="true"
                            className="mr-2" // Add margin to separate spinner from text
                            style={{ border: "5px dotted #fff" }}
                          />
                        </>
                      ) : (
                        "Subscribe"
                      )}
                    </button>
                  </div>
                  {!isValidEmail && email.trim() !== "" && (
                    <p style={{ color: "red" }}>
                      Please enter a valid email address.
                    </p>
                  )}
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
          <div class="footer-content pt-5 pb-5">
            <div class="row">
              {/* <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 mb-30">
                                <div class="footer-widget">
                                    <div class="footer-widget-heading">
                                        <h3>Product</h3>
                                    </div>
                                    <ul>
                                        <li><a href="#">Overview</a></li>
                                        <li><a href="#">Features</a></li>
                                        <li><a href="#">Solutions</a></li>
                                        <li><a href="#">Tutorials</a></li>
                                        <li><a href="#">Pricing</a></li>
                                        <li><a href="#">Releases</a></li>

                                    </ul>
                                </div>
                            </div> */}
              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 mb-30">
                <div class="footer-widget">
                  <div class="footer-widget-heading">
                    <h3>Popular Categories</h3>
                  </div>
                  <ul>
                    <li>
                      <a
                        href="#"
                        onClick={(e) =>
                          linkToLibrary(
                            e,
                            "736ABE6AC3D84934998D3D725CB8E41C9C53DEBA3EC34CD18E461F1E26975DB4",
                            "Cloud Management"
                          )
                        }
                      >
                        Cloud Management
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={(e) =>
                          linkToLibrary(
                            e,
                            "1EAB3D5EA15748FB8397DD15354B40B084CDB473A2A04B6FAE26550818080EA5",
                            "Data Science"
                          )
                        }
                      >
                        Data Science
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={(e) =>
                          linkToLibrary(
                            e,
                            "716E0D143D674DD2BD527F74CF44D1DB99A6ACC2FA70460E80735EE5416A97AC",
                            "GenAI"
                          )
                        }
                      >
                        GenAI
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={(e) =>
                          linkToLibrary(
                            e,
                            "36EFA919030E47A7B087EC2E46AD632771E4FB38FF8647D89DD4399D75C96CD0",
                            "Software Development"
                          )
                        }
                      >
                        Software Development
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={(e) =>
                          linkToLibrary(
                            e,
                            "1A4D8FE9EF1E45CC93986E0AA18E12C5C4E0E14AE8744D12B274B12A6E28646A",
                            "DevOps and CI/CD"
                          )
                        }
                      >
                        DevOps and CI/CD
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 mb-30">
                <div class="footer-widget">
                  <div class="footer-widget-heading">
                    <h3>Resources</h3>
                  </div>
                  <ul>
                    <li>
                      <a href="/blog">Blog</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 mb-30">
                <div class="footer-widget">
                  <div class="footer-widget-heading">
                    <h3>Work with us</h3>
                  </div>
                  <ul>
                    <li>
                      <a href="/explore-career">Explore Career Opportunities</a>
                    </li>
                    <li>
                      <a href="/become-trainer">Become a Trainer</a>
                    </li>
                    <li>
                      <a href="/partner-with-us">Partner with us</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-30">
                <div class="footer-widget">
                  <div class="footer-widget-heading">
                    <h3>Legal</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/legal/terms">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/legal/privacy">Privacy Policy</Link>
                    </li>
                    {/* <li><a href="#">Cookies</a></li> */}
                    <li>
                      <Link to="/legal/Cancellation-and-Refund-Policy">
                        Cancellation and Refund Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/legal/Shipping-and-Delivery-Policy">
                        Shipping and Delivery Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-30">
                <div class="footer-widget ">
                  <div class="footer-widget-heading">
                    <h3>Address</h3>
                    <ul className="h-100">
                      <li>
                        <div
                          // onClick={toggleVisibility}
                          style={{
                            color: "#EAECF0",

                            fontFamily: "Inter",
                          }}
                        >
                          INDIA{" "}
                        </div>
                        <div className="mt-3 mb-3" onClick={toggleVisibility} style={{
                          color: "#EAECF0",
                          cursor: "pointer",
                          fontFamily: "Inter",
                        }}>Delhi {ICN_HEADER_EXPAND}</div>
                        {isVisible &&
                          <p style={{
                            color: "#EAECF0",


                            fontFamily: 'Inter'
                          }}>A-87, First Floor, Kalu Sarai, Near Haus Khas Metro, South Delhi-110017
                            Mob No:9990490880</p>

                        }
                        <div className="pt-3 mb-3" onClick={toggleVisibilityUsa} style={{
                          color: "#EAECF0",
                          cursor: "pointer",
                          fontFamily: "Inter",
                          borderTop: "1px solid #373636"
                        }}>Bangalore  {ICN_HEADER_EXPAND} </div>
                        {
                          isVisibleusa &&
                          <p
                            style={{
                              color: "#EAECF0",

                              fontFamily: "Inter",
                            }}
                          >
                            H210, Hustlehub Tech Park, 36/5, Somasundarapalya Main
                            Rd, adjacent 27th Main Road, ITI Layout, Sector 2, HSR
                            Layout, HaralukunteVillage, Bengaluru, Karnataka
                            560102
                          </p>
                        }
                      </li>

                      <li>
                        <div
                          className="pt-3 mb-3"
                          // onClick={toggleVisibilityUsa}
                          style={{
                            color: "#EAECF0",
                            cursor: "pointer",
                            fontFamily: "Inter",
                            borderTop: "1px solid #373636"
                          }}
                        >
                          USA
                          {/* {ICN_HEADER_EXPAND} */}
                        </div>
                        {/* 
                                                {
                                                    isVisibleusa && <p style={{
                                                        color: "#EAECF0",


                                                        fontFamily: 'Inter', borderTop: "1px solid"
                                                    }}>8750 Perimeter Park Blvd Jacksonville, FL, 32216</p>
                                                } */}
                        <p
                          style={{
                            color: "#EAECF0",

                            fontFamily: "Inter",
                          }}
                        >
                          1000 N, WEST STREET, SUITE 1200 WILMINGTON, DELAWARE
                          19801, USA
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright-area">
          <div class="container">
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 text-lg-left">
                {ICN_LEARNLYTICA_BG_WHITE}
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-12 col-md-4 ">
                <div class="footer-menu">
                  <ul>
                    <li>
                      <Link to="https://x.com/learnlytica?s=20" target="blank">
                        Twitter
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.linkedin.com/company/learnlytica/"
                        target="blank"
                      >
                        Linkedin
                      </Link>
                    </li>
                    <li>
                      <a href="#">Facebook</a>
                    </li>
                    <li>
                      <Link
                        to="https://www.instagram.com/learnlytica_official?igsh=eGllc2Z6eDRxajBj&utm_source=qr"
                        target="blank"
                      >
                        Instagram
                      </Link>
                    </li>
                    {/* <li><a href="#">AngelList</a></li> */}
                    {/* <li><a href="#">Dribbble</a></li> */}
                  </ul>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-sm-12 col-md-4 text-right">
                <div class="copyright-text">
                  <p> &copy; 2024 Learnlytica. All rights reserved </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

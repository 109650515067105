import React, { useState, useEffect } from 'react';
const CartContext = React.createContext({});
export default CartContext;


export const CartContextProvider = (props) => {

    const [cartResponse, setCartResponse] = useState(null);
    const [currency, setCurrency] = useState('');
    const [consumerDetails, setConsumerDetails] = useState(null);

    const [couponCode, setCouponCode] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [originalCartPrice, setOriginalCartPrice] = useState(null);
    const [enterCouponCode, setEnterCouponCode] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        async function getLocation() {

            const responseLocation = await fetch("https://ipapi.co/json/");
            const jsonResponse = await responseLocation.json();
            if (jsonResponse?.country_code === 'IN') {
                setCurrency('INR');
            } else {
                setCurrency('USD');
            }
        }
        // setTimeout(() => {
            getLocation();
        // }, 2000);

    }, []);

    const appData = {
        cartResponse,
        setCartResponse,
        currency,
        setCurrency,
        consumerDetails,
        setConsumerDetails,
        couponCode,
        setCouponCode,
        discount,
        setDiscount,
        originalCartPrice,
        setOriginalCartPrice,
        enterCouponCode,
        setEnterCouponCode,
        totalPrice,
        setTotalPrice, 
    };

    return <CartContext.Provider value={{
        ...appData,
    }} > {props.children} </ CartContext.Provider>
}
import React, { useState, useEffect, useContext } from 'react';
import { GO_FORWARD, ICN_CLOCK, ICN_FEATURED, ICN_HAND, ICN_LANGUAGE, ICN_NEXT, ICN_STAR, ICN_TOTAL_LECTURE, ICN_TOTAL_TIME, IMG_ALL_COURSE_HEADER, IMG_CARD, IMG_COURSEFEATURE } from '../../Components/Common/Icon';
import "../../Components/Common/Course.css";
import { Link } from 'react-router-dom';
import SkeletonLoader from '../../Components/Common/SkeletonLoader';
import RestService from '../../Services/api.service';
import AnimationWrapper from '../../Components/Common/Animation Ref/AnimationWrapper';
import CartContext from '../../Store/CartContext';
const AllCourse = () => {
  const styles = {
    color: "#F9F5FF"
  }
  const [loader, setLoader] = useState(true);
  const [loadingTop5, setLoadingTop5] = useState(true);
  const [top5Courses, setTop5Courses] = useState([]);
  const { currency } = useContext(CartContext);


  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : '';

  //get top 5 courses
  const getTop5Courses = () => {
    try {
      RestService.getTop5Courses().then(
        response => {
          if (response.status === 200) {
            setTimeout(() => {
              setTop5Courses(response.data);
              setLoadingTop5(false);
            }, 2000);

          }

        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getTop5Courses()", err)
    }
  }

  //get top 5 courses for user
  const getTop5CoursesForLoginUser = () => {
    try {
      const consumerSid = user?.sid;
      RestService.getTop5CoursesForLoginUser(consumerSid).then(
        response => {
          if (response.status === 200) {
            setTimeout(() => {
              setTop5Courses(response.data);
              setLoadingTop5(false);
            }, 2000);
          }

        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getTop5CoursesForLoginUser()", err)
    }
  }

  useEffect(() => {
    if ('user' in localStorage) {
      getTop5CoursesForLoginUser();

    }
    else {
      getTop5Courses();
    }
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  }, []);


  return (
    <>
      <div className='allcourse-header' >
        <div className='container'>
          <div className='row parrent' style={{ alignItems: "center" }} >
            <div className='col-xl-7 col-lg-7 col-md-7 col-sm-12'>
              {loader ?
                <SkeletonLoader skeletonCount={5} />
                : <AnimationWrapper animationType="heading"><div className='ph' style={styles} >
                  All the <span style={{ textDecoration: "underline" }}> Cloud and </span>
                  <div> <span style={{ textDecoration: "underline" }}>Dev skills</span> you need </div>
                  <div> in one platform  </div>
                </div></AnimationWrapper>
              }
              {loader ?
                <SkeletonLoader skeletonCount={1} />
                :
                <AnimationWrapper animationType="subHeading">  <div className='pt mt-5' style={{ color: "#EAECF0" }}>
                  From AWS, Azure, and GCP to software development and

                  <div>data – stay up to date, learn new skills, and advance your </div>
                  <div>career with our modern tech platform.</div>

                </div> </AnimationWrapper>
              }

              {
                loader ?
                  <SkeletonLoader skeletonCount={0.5} />
                  :
                  <AnimationWrapper animationType="button">
                    <div className='mt-5'>
                      <Link to="/course-library" class="library-button">Explore course Library</Link>
                    </div>
                  </AnimationWrapper>
              }

            </div>

            <div className='col-xl-5 col-lg-5 col-md-5 col-sm-12' >
              {
                loader ?
                  <SkeletonLoader skeletonCount={5} />
                  :
                  IMG_ALL_COURSE_HEADER
              }
            </div>

          </div>

        </div>
      </div>


      {/* No of training count  */}


      <div className='layout'>

        <div className='course-layout'>
          <AnimationWrapper animationType="heading">  <div className='section-heading' >The Best DevOps Online Training Courses</div> </AnimationWrapper>
          <AnimationWrapper animationType="subHeading">  <div className='section-sub-heading'>Build the skills you need to land some of the most sought after job roles in tech</div> </AnimationWrapper>

          <div className='container m-5' style={{ background: "#EDFCF2", padding: "32px" }}>
            <div className='row' style={{ justifyContent: "space-between", textAlign: "center", alignItems: "center" }}>
              <div className='col-lg-4 col-md-4 col-sm-4'>
                <AnimationWrapper animationType="counter">
                  <div className='number'>
                    75+
                  </div>
                </AnimationWrapper>
                <div className='text-heading'>
                  Courses
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4'>
                <AnimationWrapper animationType="counter">
                  <div className='number'>
                    500+
                  </div>
                </AnimationWrapper>
                <div className='text-heading'>
                  Hands on labs
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4'>
                <AnimationWrapper animationType="counter">
                  <div className='number'>
                    900+
                  </div>
                </AnimationWrapper>
                <div className='text-heading'>
                  Hours of content
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* Features screen */}

      <div className='container'>

        <div className='features-screen'>

          <div className='title'>
            <AnimationWrapper animationType="heading">
              <h4>Features</h4>
            </AnimationWrapper>
          </div>
          <div className='row'>
            <div className='col-xl-7 col-lg-7 col-md-7 col-sm-12'>
              <AnimationWrapper animationType="heading">   <h2 className='feature-screen-header'>Grow in Multiple Ways</h2> </AnimationWrapper>
              <AnimationWrapper animationType="subHeading">          <div className='feature-screen-text'>More than 95% of our content is created by our full-time, in-house team of subject matter experts. They ensure we're always releasing cutting-edge content that keeps your skills up to date on the newest tech.</div>

              </AnimationWrapper>
            </div>


          </div>

          <div className='row'>
            <div className='col-xl-7 col-lg-7 col-md-7 col-sm-12'>
              <div className='row pt-5'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                  <div className='pb-5' >
                    {ICN_HAND}
                    <AnimationWrapper animationType="heading">         <h5 className='f1h1 pt-3'>Hands-on Learning</h5>  </AnimationWrapper>
                    <AnimationWrapper animationType="subHeading">       <p className='f1p1'>Use our hands-on labs to learn practical skills on Cloud, DevOps, Software Development, and more. Solve lab challenges to prove your skills. Build and learn with no limits, all 100% included in your subscription.
                    </p>  </AnimationWrapper>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                  <div className='pb-5' >
                    {ICN_CLOCK}
                    <AnimationWrapper animationType="heading">    <h5 className='f1h1 pt-3'>Map and track your tech skills</h5>  </AnimationWrapper>
                    <AnimationWrapper animationType="subHeading">   <p className='f1p1'>
                      Track your skills, assess your current level, and understand how to fill your skill gaps at any point in your learning journey.

                    </p>  </AnimationWrapper>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                  <div className='pb-5' >
                    {ICN_FEATURED}
                    <AnimationWrapper animationType="heading">     <h5 className='f1h1 pt-3'>Prepare for Cloud and Tech<br /> certifications</h5></AnimationWrapper>
                    <AnimationWrapper animationType="subHeading">       <p className='f1p1'>
                      Pick any cloud certification across multiple vendors and prepare with our interactive learning paths.

                    </p> </AnimationWrapper>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                  <div className='pb-5' >
                    {ICN_STAR}
                    <AnimationWrapper animationType="heading">      <h5 className='f1h1 pt-3'>Plan your goals with us</h5></AnimationWrapper>
                    <AnimationWrapper animationType="subHeading">   <p className='f1p1'>
                      Create your study plan or prepare for a new job role, select how many hours you can study per week, and let us help you stay on track.
                    </p></AnimationWrapper>
                  </div>
                </div>
              </div>
            </div>


            <div className='col-xl-5 col-lg-5 col-md-5 col-sm-12 course-feature-img' >

              {IMG_COURSEFEATURE}

            </div>

          </div>


        </div>


      </div>

      {/* explore all courses */}

      <div className='layout'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-sm-9 col-md-9 col-lg-9'>
              <AnimationWrapper animationType="heading">     <h3 className='feature-screen-header'>Curated courses for you to get started</h3></AnimationWrapper>
         {  /*   <AnimationWrapper animationType="subHeading">    <div className='feature-screen-text'>
                one supporting text goes here, not more than one line.
            </div></AnimationWrapper>*/}

            </div>

            <div className='col-12 col-sm-3 col-md-3 col-lg-3'>
              <AnimationWrapper animationType="button">
                <Link to="/course-library" class="btn-explore">Explore all Courses {GO_FORWARD}</Link></AnimationWrapper>
            </div>
          </div>

          <div class="row mt-5 mb-5">

            {
              top5Courses?.map((item) => {
                return (
                  <div class="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">

                    <div class="card py-1 px-5 h-100 ">
                      <AnimationWrapper animationType="card">
                        {/* {item.headerimg} */}
                        <div class="card-body d-flex flex-column">

                          <h4 class="card-title mt-4">{loadingTop5 ?
                            <SkeletonLoader skeletonCount={1} />
                            : item.courseName}</h4>
                          <div className='d-flex flex-wrap pt-3 gap-2'>
                            {/* <button className='card-top-btn flex-grow-1 mb-2'>{loadingTop5 ?
                      <SkeletonLoader skeletonCount={1} />
                      : item.categoryName}</button> */}
                            {/* <button className='card-top-btn flex-grow-1 mb-2'>{loadingTop5 ?
                      <SkeletonLoader skeletonCount={1} />
                      : item.subCategoryName}</button> */}
                            {
                              loadingTop5 ?
                                <SkeletonLoader skeletonCount={5} />
                                :
                                item.tags.map((tag) => {
                                  return (
                                    <button className='card-top-btn flex-grow-1 mb-2'>{tag}</button>
                                  )
                                })
                            }

                          </div>
                          <div className='d-flex gap-5 mb-4 mt-4'>
                            <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_LECTURE}</span><span className='mt-5'>{loadingTop5 ?
                              <SkeletonLoader skeletonCount={1} />
                              : item.totalContent} Lectures</span></div>
                            <div className='total card-subtitle'> <span className='mx-2'>{ICN_TOTAL_TIME}</span> <span className='mt-5'>{loadingTop5 ?
                              <SkeletonLoader skeletonCount={1} />
                              : item.totalDuration} Total Hours</span></div>
                          </div>
                          <p class="card-text gap-2"><span >{ICN_LANGUAGE}</span>{loadingTop5 ?
                            <SkeletonLoader skeletonCount={1} />
                            : item.languages?.toString()} </p>
                          <div className="course-card-h"> {currency === 'INR' ? '₹' : '$'}{loadingTop5 ?
                            <SkeletonLoader skeletonCount={1} />
                            :
                            currency === 'INR' ?
                              Math.floor(item?.price)
                              :
                              Math.floor(item?.internationalCoursePrice)
                          } </div>
                          <Link to={`/course/${item.courseSid}`} class="card-view-course mt-auto">{loadingTop5 ?
                            <SkeletonLoader skeletonCount={1} />
                            : "View Course"} {ICN_NEXT}</Link>

                          {/* <button  class="card-view-course" style={{border:"none",background:"transparent"
                        }}>View Course {ICN_NEXT}</button> */}

                        </div>
                      </AnimationWrapper>
                    </div>

                  </div>
                )
              })
            }

          </div>
        </div>
      </div>


      {/* faq */}

      {/* still have a question  */}
      <div className='layout'>
        <div className='container '>
          <div className='row hv-question-container'>
            <div className='col-lg-9 col-md-9 col-sm-12'>
              <AnimationWrapper animationType="header"> <h4 className='hv-question-title'>Still have questions?</h4></AnimationWrapper>
              <AnimationWrapper animationType="subheader"><p className='hv-question-subtitile'>Can’t find the answer you’re looking for? Please get in touch with our friendly team.</p></AnimationWrapper>
            </div>
            <div className='col-lg-3 col-md-3 col-sm-12'>
              <AnimationWrapper animationType="button">
                <Link to="/contactus" class=" btn_getintouch" style={{ float: "inline-end" }}>Get in Touch</Link>
                {/* <button class="library-button" style={{ float: "inline-end" }}>Get In Touch</button> */}
              </AnimationWrapper>
            </div>

          </div>

        </div>
      </div>

    </>
  )
}

export default AllCourse
import React, { useState, useContext } from 'react';
import { ICN_CHROME, ICN_HEADER_LOGO1, ICN_LOGO, ICN_NEXT, NEXT } from '../../Components/Common/Icon';
import "./Auth.css";
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import RestService from '../../Services/api.service';
import AxiosService from '../../Services/axios.service';
import { TokenService } from '../../Services/storage.service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ToastMessage from '../../Store/ToastHook';
import { ToastContainer } from 'react-toastify';
import CryptoJS from 'crypto-js';
import hidePwdImg from "./hide-password.svg";
import showPwdImg from "./show-password.svg";

import CartContext from '../../Store/CartContext';
import { FcGoogle } from 'react-icons/fc';
import { Spinner } from 'react-bootstrap';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

const SignupForCart = ({ setShowSignUp, addToCart, setCustomizeCourse }) => {
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [jobtitle, setJobTitle] = useState('');
    const [phone, setPhone] = useState('');
    const [about, setAbout] = useState('');
    const [showLogin, setShowLogin] = useState(false);

    const [agree, setAgree] = useState(false);
    const [emailLogin, setEmailLogin] = useState('');
    const [passwordLogin, setPasswordLogin] = useState('');
    const navigate = useNavigate();
    const secretKey = process.env.REACT_APP_SECRET;

    const [isValid, setIsValid] = useState(null);

    const [emailError, setEmailError] = useState('');
    const [nameError, setNameError] = useState('');
    const [jobTitleError, setJobTitleError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const { setConsumerDetails } = useContext(CartContext);
    const [loading, setLoading] = useState(false);
    const handleChangeName = (event) => {
        const newValue = event.target.value;
        if (/^[A-Za-z\s]*$/.test(newValue)) {
            setName(newValue);

            // Check if the length is less than 5 and the string is not empty
            if (newValue.length ===0) {
                setNameError('Please Enter your full Name');
            } else {
                setNameError(''); // Clear error if the input is valid
            }
        } else {
            setNameError('Name should only contain alphabets and spaces.');
        }
    };


    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Validate email
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(value);
        setEmailError(isValidEmail ? '' : 'Invalid email address');
    };

    const handleChangeJobTitle = (event) => {
        const newValue = event.target.value;
        setJobTitle(newValue);

        //validate jobTitle
        const isValidJobTitle = /^[A-Za-z0-9\s]*$/.test(newValue);

        setJobTitleError(isValidJobTitle ? '' : "Letters, spaces and numbers allowed.");

    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        setPhone(value);


        // Check if the value is exactly 10 digits long and consists only of numbers
        const isValidLength = value.length === 10;
        const containsOnlyNumbers = /^\d+$/.test(value);

        // Update the error message based on the validation
        if (!containsOnlyNumbers) {
            setPhoneError('Phone number must contain only digits');
        } else if (!isValidLength) {
            setPhoneError('Phone number must be exactly 10 digits');
        } else {
            setPhoneError(''); // No error
        }

        // // Validate phone number
        // const isValidPhoneNumber = /^[1-9][0-9]{0,9}$/.test(value);
        // setPhoneError(
        //     isValidPhoneNumber ? '' : 'Phone number must be 10 digits'
        // );
    };


    const validateEmail = (input) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        setIsValid(emailRegex.test(input));
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;
        setEmailLogin(inputValue);

        // Show validation message only when the user starts typing
        if (inputValue.length > 0) {
            validateEmail(inputValue);
        } else {
            setIsValid(null); // Reset validation when the input is empty
        }
    };

    // on login the user
    const onLogin = () => {
        const type = 'CONSUMER';
        // const emailEncrypt = CryptoJS.AES.encrypt(emailLogin, secretKey).toString();
        const passwordEncrypt = CryptoJS.AES.encrypt(passwordLogin, secretKey).toString();

        const payload = {
            'email': emailLogin,
            'password': passwordEncrypt
        }
        try {
            RestService.login(payload, type).then(
                response => {
                    if (response.status === 200) {
                        let data = response.data

                        // setTimeout(() => {
                        //     addToCart();
                        // }, 2000);

                        localStorage.setItem('user', JSON.stringify(data));
                        localStorage.setItem("timestamp", moment().format());
                        AxiosService.init('', response.data?.jwtToken);
                        TokenService.saveToken(response.data?.jwtToken);
                     
                        if (setShowSignUp !== undefined) {
                            setShowSignUp(false);
                        }
                        if (setCustomizeCourse !== undefined) {
                            setCustomizeCourse(false);
                        }

                        if (addToCart === undefined) {
                            window.location.reload(true);
                        }
                        if (addToCart !== undefined) {
                            addToCart();
                        }


                    }




                },
                err => {


                    if (err.request.status === 0) {

                        ToastMessage({ type: "error", message: 'Please Check Your Network Connection', time: 2500 });

                    }
                    else if (err.response.status === 401) {

                        ToastMessage({ type: "error", message: 'Invalid User Name / Password!', time: 2500 });

                    }
                    else if (err.response.status === 500) {

                        ToastMessage({ type: "error", message: `${err.response.data.message}`, time: 2500 });

                    }
                    else {

                        ToastMessage({ type: "error", message: 'User with email already exist', time: 2500 });
                    }

                }
            ).finally(() => {
                // spinner.hide();
            });
        } catch (err) {
            // Toast.error({ message: 'Invalid User Name / Password!' })
            console.error("Error occured on login page", err)
        }
    }

    const handleLoginSignup = () => {
        setShowLogin(!showLogin);
    }

    const autoLogin = async () => {
        setLoading(true);
        try {
            const emailEncrypt = CryptoJS.AES.encrypt(email, secretKey).toString();
            const firstName = CryptoJS.AES.encrypt(name, secretKey).toString();
            const hearAboutUs = CryptoJS.AES.encrypt(about, secretKey).toString();
            const jobTitle = CryptoJS.AES.encrypt(jobtitle, secretKey).toString();
            const passwordEncrypt = CryptoJS.AES.encrypt(password, secretKey).toString();
            const phoneNumber = CryptoJS.AES.encrypt(phone, secretKey).toString();
            let payload = {
                "email": emailEncrypt,
                "firstName": firstName,
                "hearAboutUs": hearAboutUs,
                "jobTitle": jobTitle,
                "password": passwordEncrypt,
                "phoneNumber": phoneNumber
            }
            const response = await RestService.autoLogin(payload);

            let data = response.data;
            setAbout('');
            setEmail('');
            setJobTitle('');
            setName('');
            setPassword('');
            setPhone('');
            localStorage.setItem('user', JSON.stringify(data));
            localStorage.setItem("timestamp", moment().format());
            AxiosService.init('', response.data?.jwtToken);
            TokenService.saveToken(response.data?.jwtToken);
         
            if (setShowSignUp !== undefined) {
                setShowSignUp(false);
            }
            if (setCustomizeCourse !== undefined) {
                setCustomizeCourse(false);
            }
            if (addToCart === undefined) {
                window.location.reload(true);
            }
            if (addToCart !== undefined) {
                addToCart();
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);

            if (err.request.status === 0) {

                ToastMessage({ type: "error", message: 'Please Check Your Network Connection', time: 2500 });

            }
            else if (err.response.status === 401) {

                ToastMessage({ type: "error", message: 'Invalid User Name / Password!', time: 2500 });

            }
            else if (err.response.status === 500) {

                ToastMessage({ type: "error", message: `${err.response.data.message}`, time: 2500 });

            }
            else {

                ToastMessage({ type: "error", message: 'User with email already exist', time: 2500 });
            }

            console.error();
        }
    };

    const onGoogleLogin = (name, google_email) => {

        const payload = {
            'email': google_email,
            "name": name,
        }
        try {
            RestService.Googlelogin(payload).then(
                response => {
                    if (response.status === 200) {

                        let data = response.data
                        setConsumerDetails(response.data?.consumerProfileDetails);
                        localStorage.setItem('user', JSON.stringify(data));
                        localStorage.setItem("timestamp", moment().format());
                        AxiosService.init('', response.data?.jwtToken);
                        TokenService.saveToken(response.data?.jwtToken);
                        setEmail('');
                        setPassword('');
                      
                        if (setShowSignUp !== undefined) {
                            setShowSignUp(false);
                        }
                        if (setCustomizeCourse !== undefined) {
                            setCustomizeCourse(false);
                        }
                        if (addToCart === undefined) {
                            window.location.reload(true);
                        }
                        if (addToCart !== undefined) {
                            addToCart();
                        }
                        // setTimeout(() => {
                        // navigate('/course-library');
                        // }, 2000);
                    }


                },
                err => {

                    if (err.request.status === 0) {

                        ToastMessage({ type: "error", message: 'Please Check Your Network Connection', time: 2500 });

                    }
                    else if (err.response.status === 401) {

                        ToastMessage({ type: "error", message: 'Invalid User Name / Password!', time: 2500 });

                    }
                    else if (err.response.status === 500) {

                        ToastMessage({ type: "error", message: `${err.response.data.message}`, time: 2500 });

                    }
                    else {

                        ToastMessage({ type: "error", message: 'User with email already exist', time: 2500 });
                    }

                    // spinner.hide();
                }
            ).finally(() => {
                // spinner.hide();
            });
        } catch (err) {
            // Toast.error({ message: 'Invalid User Name / Password!' })
            console.error("Error occured on login page", err)
        }
    }

    const responseGoogle = async (response) => {
        var userObject = jwtDecode(response.credential);

        // localStorage.setItem('user', JSON.stringify(userObject));

        const { name, sub, picture } = userObject;
        const google_email = userObject.email

        const doc = {
            _id: sub,
            _type: 'user',
            userName: name,
            image: picture,
        };
        setName(name);
        setEmail(userObject.email)
        await onGoogleLogin(name, google_email);
   
        if (setShowSignUp !== undefined) {
            setShowSignUp(false);
        }
        if (setCustomizeCourse !== undefined) {
            setCustomizeCourse(false);
        }
        // if (addToCart === undefined) {
        //     window.location.reload(true);
        // }
        // if (addToCart !== undefined) {
        //     addToCart();
        // }
        // navigate('/course-library', { replace: true });

    }


    return (
        <>
            <ToastContainer />
            {
                showLogin ?

                    <div class="signup-form">

                        <div className='text-center'>
                            {ICN_LOGO}
                        </div>
                        <h2 className='pt-4'>Create an account</h2>
                        <p className='pt-3'>Explore courses across DevOps, Cloud and more domains</p>
                        {/* <hr/> */}

                        <div class="form-group pt-5">
                            <label> Name<sup style={{ fontSize: "13px" }}>*</sup></label>
                            <div class="input-group">
                                {/* <div class="input-group-prepend">
					<span class="input-group-text">
						<span class="fa fa-user"></span>
					</span>                    
				</div> */}
                                <input type="text" onChange={handleChangeName} value={name} class="form-control" name="username" placeholder="Enter your Name" />
                            </div>
                            {nameError && <div className="error-message text-danger">{nameError}</div>}
                        </div>



                        <div class="form-group">
                            <label>Email<sup style={{ fontSize: "13px" }}>*</sup></label>
                            <div class="input-group">
                                {/* <div class="input-group-prepend">
					<span class="input-group-text">
						<i class="fa fa-paper-plane"></i>
					</span>                    
				</div> */}
                                <input type="email" onChange={handleEmailChange} value={email} class="form-control" name="email" placeholder="Email Address" />
                            </div>
                            {emailError && <div className="error-message text-danger">{emailError}</div>}
                        </div>
                        <div class="form-group">
                            <label>Password<sup style={{ fontSize: "13px" }}>*</sup></label>
                            <div class="input-group">
                                {/* <div class="input-group-prepend">
					<span class="input-group-text">
						<i class="fa fa-lock"></i>
					</span>                    
				</div> */}

                                <div className='pwd-container'>
                                    <input type={isRevealPwd ? "text" : "password"} onChange={(e) => setPassword(e.target.value)} value={password} class="form-control" name="password" placeholder="Password" />
                                    <img
                                        title={isRevealPwd ? "Hide password" : "Show password"}
                                        src={isRevealPwd ? hidePwdImg : showPwdImg}
                                        onClick={() => setIsRevealPwd(prevState => !prevState)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 col-lg-6 col-sm-12'>
                                <div class="form-group ">
                                    <label>Job Title<sup style={{ fontSize: "13px" }}>*</sup></label>
                                    <div class="input-group">
                                        {/* <div class="input-group-prepend">
					<span class="input-group-text">
						<span class="fa fa-user"></span>
					</span>                    
				</div> */}
                                        <input type="text" onChange={handleChangeJobTitle} value={jobtitle} class="form-control" name="username" placeholder="Job Title" />
                                    </div>
                                    {jobTitleError && <div className="error-message text-danger">{jobTitleError}</div>}
                                </div>
                            </div>

                            <div className='col-md-6 col-lg-6 col-sm-12'>
                                <div class="form-group ">
                                    <label>Phone Number<sup style={{ fontSize: "13px" }}>*</sup></label>
                                    <div class="input-group">
                                        {/* <div class="input-group-prepend">
					<span class="input-group-text">
						<span class="fa fa-user"></span>
					</span>                    
				</div> */}
                                        <input type="text" onChange={handlePhoneChange} value={phone} class="form-control" name="username" placeholder="Enter your Number" />
                                    </div>
                                    {phoneError && <div className="error-message text-danger">{phoneError}</div>}
                                </div>
                            </div>
                        </div>


                        <div class="form-group ">
                            <label>How did you hear about us<sup style={{ fontSize: "13px" }}>*</sup></label>
                            <div class="input-group">
                                {/* <div class="input-group-prepend">
					<span class="input-group-text">
						<span class="fa fa-user"></span>
					</span>                    
				</div> */}
                                <input type="text" onChange={(e) => setAbout(e.target.value)} value={about} class="form-control" name="username" placeholder="How did you hear about us" />
                            </div>
                        </div>





                        {/* <div class="form-group">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">
						<i class="fa fa-lock"></i>
						<i class="fa fa-check"></i>
					</span>                    
				</div>
				<input type="text" class="form-control" name="confirm_password" placeholder="Confirm Password" required="required"/>
			</div>
        </div> */}
                        {/* <div class="form-group">
			<label class="form-check-label"><input type="checkbox" required="required"/> I accept the <a href="#">Terms of Use</a> &amp; <a href="#">Privacy Policy</a></label>
		</div> */}
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary btn-lg" disabled={name.length === 0 ||
                                email.length === 0 || password.length === 0 ||
                                jobtitle.length === 0 || phone.length < 10 || about.length === 0 ||
                                nameError.length > 0 || emailError.length > 0 || jobTitleError.length > 0 ||
                                phoneError.length > 0} onClick={autoLogin}>
                                {loading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="md"
                                            role="status"
                                            aria-hidden="true"
                                            className="mr-2" // Add margin to separate spinner from text
                                            style={{ border: '5px dotted #fff' }}
                                        />
                                        {' '}
                                        Loading...
                                    </>
                                ) : 'Get started'}
                                </button>
                        </div>


                        <h3>OR</h3>
                        <div class="form-group">
                            <GoogleOAuthProvider
                                clientId="875558919636-lu0blapichklfhc7gjgfko4ff94et0m1.apps.googleusercontent.com"
                            >
                                <GoogleLogin
                                    render={(renderProps) => (
                                        <button
                                            type="button"
                                            className="bg-mainColor flex justify-center items-center p-3 rounded-lg cursor-pointer outline-none"
                                            onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}
                                        >
                                            <FcGoogle className="mr-4" /> Sign in with google
                                        </button>
                                    )}
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    cookiePolicy="single_host_origin"
                                />
                            </GoogleOAuthProvider>
                            {/* <button id='loginButton' type="submit" class="btn-login  btn-lg">{ICN_CHROME} Sign up with Google</button> */}
                        </div>





                        <div class="text-center alredyhv">Already have an account? <button style={{
                            border: "none", background: "transparent", color: "#6941c6"
                        }} onClick={handleLoginSignup} >Login here</button></div>



                    </div>
                    :
                    <>
                        <div className='container'>
                            {/* normal user */}


                            <div class="signup-form"
                            // style={{
                            //     borderRadius: "12px",
                            //     background: "var(--Primary-50, #F9F5FF)", padding: "32px"
                            // }}

                            >
                                {/* <form > */}
                                {/* <div className='text-center'>
                                {ICN_LOGO}
                            </div> */}
                                <h2 className='pt-4'>Already have an account? Log in here.</h2>
                                <p className='pt-3'>Welcome back! Please enter your details.</p>
                                {/* <hr/> */}

                                <div class="form-group">
                                    <label>Email<sup style={{ fontSize: "13px" }}>*</sup></label>
                                    <div class="input-group">
                                        {/* <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-paper-plane"></i>
                            </span>                    
                        </div> */}
                                        <input type="email" class="form-control" name="email" placeholder="Email Address" onChange={handleChange} />
                                    </div>
                                    {isValid === false && (
                                        <div className='text-danger'>Email is not valid</div>
                                    )}
                                </div>
                                <div class="form-group">
                                    <label>Password<sup style={{ fontSize: "13px" }}>*</sup></label>
                                    <div class="input-group">
                                        {/* <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-lock"></i>
                            </span>                    
                        </div> */}

                                        <div className='pwd-container'>
                                            <input type={isRevealPwd ? "text" : "password"} class="form-control" name="password" placeholder="Password" onChange={(e) => setPasswordLogin(e.target.value)} />
                                            <img
                                                title={isRevealPwd ? "Hide password" : "Show password"}
                                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-lock"></i>
                                <i class="fa fa-check"></i>
                            </span>                    
                        </div>
                        <input type="text" class="form-control" name="confirm_password" placeholder="Confirm Password" required="required"/>
                    </div>
                </div> */}
                                {/* <div class="form-group">
                    <label class="form-check-label"><input type="checkbox" required="required"/>
                    
                     I accept the <a href="#">Terms of Use</a> &amp; <a href="#">Privacy Policy</a>
                    
                    
                    </label>
                </div> */}
                                {/* <div class="form-group" style={{ justifyContent: "space-between" }}>
                                <label class="form-check-label"><input type="checkbox" required="required" /> Remember me</label>
                                <Link to="/forgotpassword" style={{ float: "right" }}>Forgot Password</Link>
                            </div> */}
                                <div class="form-group">
                                    <button type="submit" class="btn btn-primary btn-lg" onClick={onLogin} disabled={!isValid || passwordLogin.length === 0}>Sign in</button>
                                </div>


                                <h3>OR</h3>
                                <div class="form-group">
                                    <GoogleOAuthProvider
                                        clientId="875558919636-lu0blapichklfhc7gjgfko4ff94et0m1.apps.googleusercontent.com"
                                    >
                                        <GoogleLogin
                                            render={(renderProps) => (
                                                <button
                                                    type="button"
                                                    className="bg-mainColor flex justify-center items-center p-3 rounded-lg cursor-pointer outline-none"
                                                    onClick={renderProps.onClick}
                                                    disabled={renderProps.disabled}
                                                >
                                                    <FcGoogle className="mr-4" /> Sign in with google
                                                </button>
                                            )}
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            cookiePolicy="single_host_origin"
                                        />
                                    </GoogleOAuthProvider>
                                    {/* <button id='loginButton' type="submit" class="btn-login  btn-lg">{ICN_CHROME} Sign up with Google</button> */}
                                </div>




                                {/* </form> */}

                                <div class="text-center alredyhv">Don’t have an account?
                                    <button style={{
                                        border: "none", background: "transparent", color: "#6941c6"
                                    }} onClick={handleLoginSignup} >Sign up</button>
                                </div>



                            </div>


                            {/* guest user */}


                        </div>



                    </>

            }

        </>
    )
}

export default SignupForCart
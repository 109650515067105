import React ,{useState,useEffect}from 'react'
import Legal from './Legal'
import SkeletonLoader from '../../Components/Common/SkeletonLoader';

const Privicypolicy = () => {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  }, []);
  return (
    <>
<div className='course-container'>
<div className='row '>
    {/*  filter options */}
<div className='col-lg-3 col-md-3 col-sm-12  mb-5' >
    <Legal/>
    </div>

   
    <div className='col-lg-9 col-md-9 col-sm-12  mb-5' >
    <div className='course-section'>
       
          {loader ?
                  <SkeletonLoader skeletonCount={15} />:
            < div className='container'>
            <div className='learningpt-header' >Privacy Policy</div>
            <div className='requirment pt-5'>Last updated on Jan 2nd 2024</div>
            

            <div className='requirment pt-5'>
            This privacy policy sets out how LEARNLYTICA PRIVATE LIMITED uses and protects any information that you give LEARNLYTICA PRIVATE LIMITED when you use this website 
               </div>

<div className='requirment pt-5'>LEARNLYTICA PRIVATE LIMITED is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.</div>
           
<div className='requirment pt-5'>LEARNLYTICA PRIVATE LIMITED may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</div> 



<h5 className='requirment pt-5'>We may collect the following information:</h5> 
         
            <div className='requirment'>
              <ul className='pt-3'>
                <li >Name and job title</li>
                <li className='pt-3'>Contact information including email address</li>
                <li className='pt-3'>Demographic information such as postcode, preferences and interests</li>
                
                <li className='pt-3'>Other information relevant to customer surveys and/or offers</li>
                


              </ul>
            
            
            </div>
<div className='requirment pt-5'>What we do with the information we gather</div>
            <h5 className='requirment pt-5'>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</h5> 
         
         <div className='requirment'>
           <ul className='pt-3'>
             <li >Internal record keeping.</li>
             <li className='pt-3'>We may use the information to improve our products and services.</li>
             <li className='pt-3'>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
             
             <li className='pt-3'>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</li>
             


           </ul>
         
         
         </div>



         <div className='requirment pt-5'>
         We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in suitable measures.  
               </div>


               <h5 className='requirment pt-5'>How we use cookies</h5>

<div className='requirment pt-5'>
A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyses web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.   
               </div>

<div className='requirment pt-5'>
We use traffic log cookies to identify which pages are being used. This helps us analyses data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
    </div>
           
<div className='requirment pt-5'>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</div> 

  


            
            
            
            
            
            
            </div>
}


    </div>



    
    
    
    </div>
   

    </div>
</div>
    </>
  )
}

export default Privicypolicy
import React, { useEffect } from 'react';
import RestService from '../../Services/api.service';
import moment from 'moment';
import { useNavigate } from "react-router-dom";

const LogoutUser = () => {

    const navigate = useNavigate();

    //calculate timespend in portal
    const getLogoutTimes = () => {
        try {
            const dateNow = moment().format();
            const dateLarger = moment(dateNow);
            const dateStored = localStorage.getItem("timestamp");
            let timeSpent = dateLarger.diff(dateStored);
            RestService.getLogoutTimes(timeSpent).then(res => {
                localStorage.clear();
                navigate("/");

            }, err => { console.err('') }
            )
        }
        catch (err) {
            console.error('error occur on getLogoutTimes()', err)
            // Toast.error({ message: `Something wrong!!` });
        }
    }

    useEffect(() => {
        getLogoutTimes();
    })

    return (
        <></>
    )
}

export default LogoutUser